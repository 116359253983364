<template>
    <transition name="modal">
        <ModalComponent :isSuccess="true">
            <template #header>Carica nuova immagine</template>

            <template #body>
                <div class="d-flex flex-column justify-content-between pt-3">
                    <div>
                        <label class="fs-6">Carica una nuova immagine di profilo</label>

                        <div class="w-70">
                            <FileInput
                                :ref="'fileInput'"
                                :validationStyle="imageFile === null ? '' : isImageValid ? 'is-valid mb-0' : 'is-invalid mb-0'"
                                :fileName="imageName"
                                :accept="'image/*'"
                                @handleFileChange="handleImageChange"
                                @handleFileDrop="handleImageDrop"
                            />
                        </div>

                        <div v-if="imageFile !== null">
                            <p v-if="!isImageValid" class="invalid-feedback fs-6 text-bolder d-block m-0">File non supportato</p>
                            <p v-else class="valid-feedback fs-6 text-bolder d-block m-0">File supportato</p>
                        </div>
                    </div>
                </div>
            </template>

            <template #footer>
                <ArgonButton
                    color="primary"
                    class="ms-auto mx-1 py-2 px-3 btn secondary fs-6"
                    size="sm"
                    @click.prevent="$emit('dismiss')"
                >Annulla</ArgonButton>

                <ArgonButton
                    :disabled="imageFile === null || !isImageValid"
                    class="ms-auto mx-1 py-2 px-3 fs-6"
                    color="primary"
                    size="sm"
                    @click.prevent="handleUpdateStoreImage()"
                >Invia</ArgonButton>
            </template>
        </ModalComponent>
    </transition>
</template>

<script>
import axios from 'axios';
import ModalComponent from '../ModalComponent.vue';
import ArgonButton from '../../ArgonButton.vue';
import FileInput from '../../FileInput.vue';

export default {
    name: 'UploadImageModalComponent',
    components: {
        ModalComponent,
        ArgonButton,
        FileInput
    },
    props: {
        storeId: {
            type: Number,
            default: 0
        }
    },
    emits: [
        'show-loader',
        'hide-loader',
        'handle-toast',
        'handle-image-uploaded',
        'dismiss'
    ],
    data() {
        return {
            imageFile: null,
            imageName: null
        }
    },
    methods: {
        handleImageChange(event) {
            this.imageFile = event.target.files[0];
            this.imageName = this.imageFile.name;
        },

        handleImageDrop(event) {
            this.file = event.dataTransfer.files[0];
            this.fileName = this.file.name;
        },

        handleUpdateStoreImage() {
            this.$emit('show-loader');

            if(!this.isImageValid || this.imageFile === null) {
                const errorMessage = 'Inserisci un\'immagine valida';
                this.$emit('handle-toast', errorMessage);
                this.$emit('hide-loader');
                return null;
            }

            const formData = new FormData();
            formData.append('image', this.imageFile);
            formData.append('farmaciaId', this.storeId);

            axios.post(`/image/uploadImage`, formData)
                .then(() => {
                    this.$emit('handle-image-uploaded');
                    this.imageFile = null;
                })
                .catch((err) => {
                    this.$emit('hide-loader');
                    this.$emit('handle-toast', err.message);
                })
        },
    },
    computed: {
        isImageValid() {
            return Boolean(this.imageFile?.type.startsWith('image/'));
        },
    }
}
</script>
