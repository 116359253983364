import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import './assets/css/nucleo-icons.css';
import './assets/css/nucleo-svg.css';
import ArgonDashboard from './argon-dashboard';
import { interceptor } from './api/axios';
import axios from 'axios';
import keycloak from './keycloak';

const appInstance = createApp(App);
appInstance.use(interceptor);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.mount('#app');


const checkKeycloakLogin = async () => {
	try {
		const config = {
			onLoad: 'check-sso',
			pkceMethod: 'S256',
			checkLoginIframe: false,
			redirectUri: window.origin + '/keycloak-callback',
			// silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
			// enableLogging: true,
		};

		const isAuthenticated = await keycloak.init(config);

		if (isAuthenticated) {
			console.log('>>>>>> Autorizzato');

			localStorage.setItem('access-token', keycloak.token);
			localStorage.setItem('keycloak-refresh', keycloak.refreshToken);
			localStorage.setItem('keycloak-token-id', keycloak.idToken);
		} else {
			console.log('>>>>> Non autorizzato');
			localStorage.clear();
		}
	} catch (error) {
		console.error('Failed to initialize adapter:', error);
	}
};

checkKeycloakLogin();

keycloak.onTokenExpired = async () => {
	try {
		await keycloak.updateToken(30);
		console.info('>>>>> Token aggiornato');

		localStorage.setItem('access-token', keycloak.token);
	} catch (error) {
		console.error('>>>>> Errore #%d - messaggio: ', error);
	}
};

axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT;
