<template>
    <transition name="modal">
        <ModalComponent :isSuccess="true">
            <template #header>Carica Documentazione</template>

            <template #body>
                <div class="d-flex flex-column justify-content-between pt-3">
                    <div>
                        <div v-if="userType === 1">
                            <label v-if="selectedInstance.statoCamunda === 'carica_autocertificazione'" class="fs-6 text-bold text-dark">
                                Carica Autocertificazione
                            </label>

                            <label v-else class="fs-6 text-bold text-dark">
                                Carica decreto con marca da bollo
                            </label>
                        </div>

                        <div v-else>
                            <label class="fs-6 text-bold text-dark">Carica decreto</label>
                        </div>

                        <div class="w-70">
                            <FileInput
                                :ref="'fileInput'"
                                :validationStyle="file === null ? '' : isFileValid ? 'is-valid mb-0' : 'is-invalid mb-0'"
                                :fileName="fileName"
                                @handleFileChange="handleFileChange"
                                @handleFileDrop="handleFileDrop"
                            />
                        </div>

                        <div v-if="file !== null" class="mt-2">
                            <p v-if="!isFileValid" class="invalid-feedback text-xs text-bolder d-block m-0">File non supportato</p>
                            <p v-else class="valid-feedback text-xs text-bolder d-block m-0">File supportato</p>
                        </div>
                    </div>
                </div>
            </template>

            <template #footer>
                <ArgonButton
                    color="primary"
                    class="ms-auto mx-1 py-2 px-3 bg-dark fs-6"
                    size="sm"
                    @click.prevent="$emit('dismiss')"
                >Annulla</ArgonButton>

                <ArgonButton
                    :disabled="!isFileValid"
                    class="ms-auto mx-1 py-2 px-3 bg-primary fs-6"
                    color="primary"
                    size="sm"
                    @click.prevent="handleSubmitUploadDocument()"
                >Invia</ArgonButton>
            </template>
        </ModalComponent>
    </transition>
</template>

<script>
import axios from 'axios';
import ModalComponent from '../ModalComponent.vue';
import ArgonButton from '../../ArgonButton.vue';
import FileInput from '../../FileInput.vue';

const VALID_FILE = 'application/pdf';

export default {
    name: 'UploadImageModalComponent',
    components: {
        ModalComponent,
        ArgonButton,
        FileInput
    },
    props: ['userType', 'selectedInstance'],
    emits: [
        'show-loader',
        'hide-loader',
        'handle-toast',
        'handle-file-uploaded',
        'dismiss'
    ],
    data() {
        return {
            file: null,
            fileName: null
        }
    },
    methods: {
        handleFileChange(event) {
            this.file = event.target.files[0];
            this.fileName = this.file.name;
        },

        handleFileDrop(event) {
            this.file = event.dataTransfer.files[0];
            this.fileName = this.file.name;
        },

        handleSubmitUploadDocument() {
            this.$emit('show-loader');

            if(this.file === null) {
                this.$emit('hide-loader');
                const errorMessage = 'Inserire un file valido';
                this.$emit('handle-toast', errorMessage);
                return null;
            }

            let url = '';
            if(this.selectedInstance.statoCamunda !== 'carica_autocertificazione') {
                url = `/document/uploadDecreto`
            } else {
                url = `/document/uploadAutocertificazione`;
            }

            const formData = new FormData();
            formData.append('file', this.file);
            formData.append('istanzaId', this.selectedInstance.istanzaId);

            axios.post(url, formData)
                .then(() => {
                    this.file = null;
                    this.$emit('handle-file-uploaded')
                })
                .catch(err => {
                    this.$emit('hide-loader');
                    this.$emit('handle-toast', err.message);
                })
        },
    },
    computed: {
        isFileValid() {
            return Boolean(this.file?.type === VALID_FILE);
        },

    }
}
</script>

<style scoped>
.file-input-container {
    width: min(100%, 400px);

}
</style>
