import Keycloak from "keycloak-js";

const initOptions = {
	url: window.VUE_APP_SPID_URL,
	clientId: 'justheal',
	realm: 'hcsinfonia',
};

const keycloak = new Keycloak(initOptions);

export default keycloak;
