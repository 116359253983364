<!--
=========================================================
* Vue Argon Dashboard 2 - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->
<template>
	<!-- <div 
        v-show="this.$store.state.layout === 'landing'"
        class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
    ></div> -->
	<div
		v-show="isTopStyleSectionShown"
		class="min-height-300 bg-dark position-absolute w-100"
		:class="`${this.$store.state.darkMode ? 'bg-transparent' : 'bg-success'}`"
	></div>

	<header class="d-flex justify-content-between bg-white">
		<div class="img_png p-2">
			<img
				src="./assets/img/logo-regione-campania.png"
				class="rounded"
				alt="Logo Regione Campania"
			/>
		</div>

		<div class="img_png p-2">
			<img
				src="./assets/img/logo_accreditamento.png"
				class="rounded"
				alt="Logo Justheal"
			/>
		</div>

		<div class="img_png p-2">
			<img
				src="./assets/img/logo-sinfonia.png"
				class="rounded"
				alt="Logo Sinfonia"
			/>
		</div>
	</header>

	<Sidenav
		:custom_class="this.$store.state.mcolor"
		:class="[this.$store.state.isTransparent, this.$store.state.isRTL ? 'fixed-end' : 'fixed-start']"
		v-if="this.$store.state.showSidenav && this.$route.name !== 'AslInvolvement' && user !== null"
	/>

	<main
		class="main-content position-relative h-100 border-radius-lg"
		:class="{ 'no-transition': getRoute === 'signin' }"
	>
		<!-- nav -->
		<navbar
			:class="[navClasses]"
			:textWhite="this.$store.state.isAbsolute ? 'text-white opacity-8' : 'text-white'"
			:minNav="navbarMinimize"
			v-if="this.$store.state.showNavbar && this.$route.name !== 'AslInvolvement' && user !== null"
		/>

		<div class="main-content-spacer">
			<router-view />
		</div>

		<!-- 
        <Configurator
            :toggle="toggleConfigurator"
            :class="[
                this.$store.state.showConfig ? 'show' : '',
                this.$store.state.hideConfigButton ? 'd-none' : ''
            ]" 
        /> -->
	</main>

	<AppFooter v-show="this.$store.state.showFooter" />
</template>
<script>
import Sidenav from './examples/Sidenav';
// import Configurator from "@/examples/Configurator.vue";
import Navbar from '@/examples/Navbars/Navbar.vue';
import AppFooter from '@/examples/Footer.vue';
import { mapMutations } from 'vuex';

export default {
	name: 'App',
	components: {
		Sidenav,
		// Configurator,
		Navbar,
		AppFooter,
	},
	data() {
		return {
			user: null,
		};
	},
	methods: {
		...mapMutations(['toggleConfigurator', 'navbarMinimize']),
	},
	computed: {
		navClasses() {
			return {
				'position-sticky bg-white left-auto top-2 z-index-sticky': this.$store.state.isNavFixed && !this.$store.state.darkMode,
				'position-sticky bg-default left-auto top-2 z-index-sticky': this.$store.state.isNavFixed && this.$store.state.darkMode,
				'position-absolute px-4 mx-0 w-100 z-index-2': this.$store.state.isAbsolute,
				'px-0 mx-0': !this.$store.state.isAbsolute,
			};
		},
		getRoute() {
			const routeArr = this.$route.path.split('/');
			return routeArr[1];
		},
		isTopStyleSectionShown() {
			if (this.$store.state.layout === 'default') {
				if (this.getRoute !== 'signin' && this.getRoute !== 'signup') return true;
				return false;
			}
			return false;
		},
		userFromState() {
			return this.$store.state.user;
		},
	},
	beforeMount() {
		this.$store.state.isTransparent = 'bg-transparent';
		this.user = localStorage.getItem('user');
		// console.log('[USER]', this.user)
		// console.log(`[JWTTOKEN]: ${localStorage.getItem('access-token')}`)
	},
	watch: {
		$route(to) {
			document.title = `Istanze Online - ${to.meta.title}`;
		},

		userFromState(user) {
			if (user !== null) {
				this.user = user;
			}
		},
	},
};
</script>

<style>
html {
	font-size: calc(9px + 0.390625vw);
}

.main-content-spacer {
	min-height: calc(100vh - 13rem);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.main-content.no-transition {
	transition: none;
}

.img_png {
	width: 9.2rem;
}

.img_png img {
	width: 100%;
}

header.d-flex {
	height: 5rem;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	align-items: center;
}

main,
main.main-content {
	margin-top: 10rem;
	height: calc(100vh - 12rem);
	max-height: calc(100vh - 12rem);
	padding-bottom: 1rem;
}

main.main-content {
	overflow-y: auto;
}

.dp-custom-input {
	border-radius: 0.5rem !important;
	height: 2.5rem;
}

.login-input > div > * {
	height: 50px !important;
	font-size: 1.3rem;
}

.login-input.p-iva > div > * {
	padding-right: 0 !important;
}

.search-input > div > div > input {
	border-color: #326798;
}

.dp-custom-input.signup {
	height: 2.5rem;
}

.dp-main {
	z-index: 999999;
}
</style>
