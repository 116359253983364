<template>
  <div
    class="alert alert-absolute alert-sm text-white font-weight-bold"
    role="alert"
    :class="getClasses(color, dismissible)"
  >
    <span class="alert-icon" v-if="icon">
      <i :class="getIcon(icon)" />
    </span>
    <span class="alert-text">
      <!-- &nbsp; -->
      <slot name="modalTitle" />
      <slot name="modalText" />
    </span>
    <button
      v-if="dismissible"
      type="button"
      class="btn-close d-flex justify-content-center align-items-center"
      data-bs-dismiss="alert"
      aria-label="Close"
      @click="this.$emit('modalDismiss')"
    >
      <span aria-hidden="true" class="text-lg font-weight-bold">&times;</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "argon-alert",
  props: {
    color: {
      type: String,
      default: "success",
    },
    icon: String,
    dismissible: {
      type: Boolean,
      default: false,
    },
    isShown: {
      type: Boolean,
      default: false
    },
    delay: {
      type: Number,
      default: 3000
    }
  },
  methods: {
    getClasses: (color, dismissible) => {
      let colorValue, dismissibleValue;

      colorValue = color ? `alert-${color}` : null;

      dismissibleValue = dismissible ? "alert-dismissible fade show" : null;

      return `${colorValue} ${dismissibleValue}`;
    },
    getIcon: (icon) => (icon ? icon : null),
  },
  watch: {
    isShown() {
      if(this.isShown === true && this.$props.delay !== 0) {
        setTimeout(() => {
          this.$emit('modal-dismiss');
        }, this.$props.delay);
      }
    }
  }
};
</script>

<style scoped>
.alert.alert-absolute {
  z-index: 10001 !important;
}
</style>