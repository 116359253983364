export const USER_TYPE_ACTIONS_MAP = {
	1: {
		null: 'edit',
		bozza: 'edit',
		smistata: 'view',
		note: 'edit',
		decreto_inviato: 'download',
		attesa_bollo: 'upload',
		carica_autocertificazione: 'upload',
		scarica_autocertificazione: 'download',
	},
	3: {
		bozza: 'review',
		smistata: 'review',
		validati: 'download',
		attesa_upload: 'upload',
		decreto_completato: 'confirm',
		note: 'review',
	},
	4: {
		bozza: 'sort',
		inviata: 'sort',
	},
};

export const INSTANCES_STEPS_MAP = {
	1: 11,
	2: 8,
	3: 7,
	4: 7,
	5: 7,
	6: 9,
	7: 4,
	8: 8,
	9: 8,
	10: 11,
	11: 8,
	12: 8,
	13: 8,
	14: 8,
	15: 8,
	16: 8,
	17: 8,
	18: 7,
	19: 3,
	20: 3,
	21: 2,
	22: 2,
};

export const DASHBOARD_ALLOWED_ACTIONS_MAP = {
	null: 'edit',
	bozza: 'edit',
	note: 'edit',
	richiesta_pubblicazione: 'no-action-available',
	decreto_inviato: 'download',
	scarica_autocertificazione: 'download',
	attesa_bollo: 'upload',
	carica_autocertificazione: 'upload',
};

export const NO_ACTIONS_INSTANCES_BADGE_MAP = {
	1: [
		'validati',
		'rigettata',
		'completata',
		'smistata',
		'attesa_upload',
		'richiesta_pubblicazione',
		'decreto_completato'
	],
    3: [
		'rigettata',
		'completata',
		'decreto_inviato',
		'attesa_bollo'
	]
}

export const SELF_CERTIFICATION_ID = 25

export const INSTANCES_FILTER_TYPE = {
	1: 'istanzaId',
	2: 'descrizione',
	3: 'dataUltimaModifica',
	4: 'statoCamunda',
	5: 'richiestaCredenzialiId',
	6: 'nomeUtente',
	7: 'codiceFiscale',
	8: 'ruoloUtente',
	9: 'sede',
	10: 'nomeFarmacia',
	11: 'comune',
	12: 'stato'
};

export const IGNORED_PATHS_MAP = {
	1: ['DashboardAmministratori', 'UsersManagement', 'InstanceManagement', 'EntrustedRequests', 'RequestsApproval', 'ActiveInstances', 'UserSelfDeclaration', 'AddFarmacia', 'AddDelegate'],
	3: ['Dashboard', 'Profile', 'ListaFarmacie', 'UsersManagement', 'InstanceManagement', 'Request', 'ActiveInstances', 'UserSelfDeclaration', 'AddFarmacia', 'ListDelegates', 'AddDelegate'],
	4: ['Dashboard', 'Profile', 'ListaFarmacie', 'Request', 'EntrustedRequests', 'RequestsApproval', 'UserSelfDeclaration', 'AddFarmacia', 'ListDelegates', 'AddDelegate'],
};

export const USER_ALLOWED_PATHS_MAP = {
	1: [
		'Dashboard',
		'ListaFarmacie',
		'AddFarmacia',
		'EditFarmacia',
		'Profile',
		'Reports',
		'ListDelegates',
		'AddDelegate',
		'NewRequest',
		'ReviewRequest',
		'InProgressRequests',
		'RequestsArchive',
		'NotFound'
	],
	3: [
		'DashboardAmministratori',
		'EntrustedRequest',
		'ReviewEntrustedRequest',
		'EntrustedRequestsArchive',
		'Reports',
		'NotFound'
	],
	4: [
		'DashboardAmministratori',
		'InstancesSorting',
		'ActiveInstances',
		'InstancesArchive',
		'Reports',
		'NotFound'
	],
};
