<template>
    <label for="file-input" class="form-group d-flex" :class="validationStyle" @drop="handleFileDrop($event)">
        <span
            aria-label="file-button"
            class="px-3 fs-6 fw-bold"
        >
            <span class="text-truncate">
                Scegli file
            </span>
        </span>

        <span aria-label="file-name" class="form-control pe-4 fs-6 text-truncate" :class="validationStyle">
            {{ fileName || 'Nessun file selezionato.' }}
        </span>

        <input
            class="form-control fs-6"
            type="file"
            id="file-input"
            :required="isRequired"
            :ref="reference"
            :accept="accept"
            @change="handleFileChange($event)"
        />
    </label>

</template>

<script>
export default {
    name: 'FileInput',
    props: {
        accept: {
            type: String,
            default: '.pdf'
        },
        validationStyle: {
            type: String,
            default: ''
        },
        reference: {
            required: true,
            type: [String, undefined],
            default: 'input'
        },
        fileName: {
            type: [String, null],
            required: true,
        },
        isRequired: {
            type: Boolean,
            default: false
        }
    },
    emits: ['handle-file-change', 'handle-file-drop'],
    methods: {
        handleFileChange(event) {
            event.preventDefault();
            this.$emit('handle-file-change', event)
        },

        handleFileDrop(event) {
            event.preventDefault();
            this.$emit('handle-file-drop', event);
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/argon-dashboard/custom/variables';
input {
    display: none;
    border-radius: .5rem;
}

label {
    display: flex;
    cursor: pointer;
    &:hover [aria-label="file-button"] {
        filter: brightness(1.1);
    }

    [aria-label="file-button"] {
        background-color: $primary;
        border: 0;
        color: $neutral-light;
        border-radius: .5rem;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        display: flex;
        align-items: center;
        flex: 1;
        max-width: 30%;
    }

    [aria-label="file-name"] {
        flex: 5;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
}
</style>
