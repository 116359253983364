<template>
    <div class="modal-card">
        <div class="card">
            <div
                class="card-header d-flex justify-content-between align-items-center p-3 text-bold fs-5 text-uppercase text-white"
                :class="isSuccess ? 'bg-dark' : 'bg-danger'"
            >
                <slot name="header"></slot>
            </div>

            <div class="card-body py-1 p-3">
                <slot name="body"></slot>
            </div>

            <div v-if="isFooterRequired" class="card-footer p-3 text-end">
                <slot name="footer">
                    <ArgonButton
                        class="fs-6"
                        size="sm"
                        color="primary"
                        @click="$emit('close')"
                    >OK</ArgonButton>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import ArgonButton from '../ArgonButton.vue';

export default {
    name: "ModalComponent",
    components: { ArgonButton },
    emits: ['close'],
    props: {
        isSuccess: {
            type: Boolean,
            default: false
        },
        isFooterRequired: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style scoped>
.modal-card {
    position: fixed;
    inset: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    background-color: #00000080;
    transition: opacity 0.1s ease;
}

.card {
    width: min(80%, 600px);
    transition: all 0.1s ease;
}

.modal-enter-from,
.modal-leave-to {
    opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>