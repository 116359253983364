<template>
    <div class="card shadow-lg h-100">
        <div class="pb-1 card-header mb-0">
            <h5 v-if="title" class="fs-5 text-uppercase fw-normal text-dark">{{ title }}</h5>
        </div>

        <div class="p-3 pt-1 card-body">
            <div class="chart">
                <canvas :id="id" class="chart-canvas fs-6" :height="height"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from "chart.js/auto";

const BACKGROUND_CHANNELS_COLORS = [
    '50, 103, 152',
    '226, 89, 89',
    '186, 232, 197'
];

const CHART_TICS = {
    display: true,
    color: "#252B33",
    padding: 20,
    font: {
        size: 15,
        family: "Open Sans",
        style: "normal",
        lineHeight: 2
    }
};

const CHART_X_AXIS = {
    grid: {
        drawBorder: false,
        display: false,
        drawOnChartArea: false,
        drawTicks: false,
        borderDash: [5, 5],
    },
    ticks: CHART_TICS,
};

const CHART_Y_AXIS = {
    grid: {
        drawBorder: true,
        display: true,
        drawOnChartArea: true,
        drawTicks: false,
        borderDash: [5, 5],
    },
    ticks: CHART_TICS,
};

export default {
    name: "gradient-line-chart",
    props: {
        id: {
            required: true,
            type: String
        },
        height: {
            type: Number,
            default: 300
        },
        title: { type: String },
        color: {
            type: String,
            default: "#4BB543"
        },
        showLegend: {
            type: Boolean,
            default: false
        },
        legends: {
            type: Array,
            default: () => ['Test']
        },
        labels: {
            type: Array,
            default: () => ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"]
        },
        data: {
            type: Array,
            default: () => [
                [0, 12, 50, 87, 300, 220, 236, 250, 400, 230, 279, 297],
            ]
        }
    },
    mounted() {
        const context = document.getElementById(`${this.$props.id}`).getContext("2d");
        this.createNewChart(context, this.createDataSets(context));
    },
    methods: {
        createDataSets(context) {
            const dataSets = this.$props.legends.map((legend, i) => {
                const color = 'rgba(' + BACKGROUND_CHANNELS_COLORS[i] + ', 1)';
                const colorChannels = BACKGROUND_CHANNELS_COLORS[i].split(',');
                const gradientStroke = context.createLinearGradient(colorChannels[0], colorChannels[1], colorChannels[2], 50);

                gradientStroke.addColorStop(1, "rgba(" + BACKGROUND_CHANNELS_COLORS[i] + ", 0.3)");
                // gradientStroke.addColorStop(0.2, "rgba(" + BACKGROUND_CHANNELS_COLORS[i] + ", 0.0)");
                // gradientStroke.addColorStop(0, "rgba(" + BACKGROUND_CHANNELS_COLORS[i] + ", 0)");

                return {
                    label: legend,
                    tension: 0.3,
                    pointRadius: 0,
                    borderColor: color,
                    backgroundColor: gradientStroke,
                    borderWidth: 2,
                    fill: true,
                    data: this.$props.data[i],
                    maxBarThickness: 6,
                }
            })

            return dataSets;
        },

        createNewChart(context, dataSets) {
            const chartType = this.$props.labels.length > 1 ? 'line' : 'bar'     

            new Chart(context, {
                type: chartType,
                data: {
                    labels: this.$props.labels,
                    datasets: dataSets,
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: this.$props.showLegend,
                            labels: {
                               font: {
                                    size: 15
                                }
                            }
                        },
                    },
                    interaction: { intersect: false, mode: "index" },
                    scales: { y: CHART_Y_AXIS, x: CHART_X_AXIS },
                },
            });
        }
    }
};
</script>
