<template>
    <nav 
        class="navbar navbar-main bg-dark navbar-expand-lg px-0 mx-0 shadow-none" 
        :class="this.$store.state.isRTL ? 'top-0 position-sticky z-index-sticky' : ''"
        v-bind="$attrs"
        id="navbarBlur"
        data-scroll="true"
    >
        <div class="py-3 px-4 container-fluid">
            <Breadcrumbs
                :currentPage="currentRouteName"
                textWhite="text-white"
            />

            <div class="mt-2 d-flex justify-content-end collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4" :class="this.$store.state.isRTL ? 'px-0' : 'me-sm-4'" id="navbar">
                <ul class="navbar-nav justify-content-end align-items-center">
                    <li class="nav-item d-flex align-items-center">
                        <div v-if="(user !== null)" class="d-flex align-items-center gap-3">
                            <div class="position-relative">
                                <i class="fa fa-bell"></i>

                                <div v-if="$store.state.notifications > 0" class="badge position-absolute top-0 start-100 translate-middle rounded-circle overflow-hidden d-flex justify-content-center align-items-center">
                                    <span class="bg-danger font-weight-bolder p-2">
                                        {{ $store.state.notifications > 99 ? '+99' : $store.state.notifications }}
                                    </span>
                                </div>
                            </div>

                            <div class="d-none clr-neutral-light px-3 d-lg-flex flex-column justify-content-center">
                                <h6 class="clr-neutral-light text-sm m-0 fs-6"> {{ user.flagUserGuest === 0 ? user.role : 'Delegato' }} </h6>
                                <span class="clr-neutral-light fs-6"> {{ user.email }} </span>
                            </div>

                            <ArgonButton
                                @click="logout()"
                                color="danger"
                                size="sm"
                                variant="linear"
                                class="mb-0 d-flex justify-content-center align-items-center nav-link font-weight-bold text-white fs-6"
                            >
                                <i class="fa fa-user" :class="this.$store.state.isRTL ? 'ms-sm-2' : 'me-sm-2'"></i>
                                <span class="d-sm-inline d-none fs-6">Log Out</span>
                            </ArgonButton>

                            <ul class="p-0">
                                <li class="nav-item d-xl-none ps-1 pe-3 d-flex align-items-center">
                                    <a href="#" @click="toggleSidebar" class="p-0 nav-link text-white" id="iconNavbarSidenav">
                                        <div class="sidenav-toggler-inner">
                                            <i class="sidenav-toggler-line bg-white"></i>
                                            <i class="sidenav-toggler-line bg-white"></i>
                                            <i class="sidenav-toggler-line bg-white"></i>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>    
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import axios from 'axios';
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions, mapState } from "vuex";
import ArgonButton from "../../components/ArgonButton.vue";
import keycloak from '../../keycloak';

export default {
    name: "NavbarComponent",
    components: {
        Breadcrumbs,
        ArgonButton
    },
    data() {
        return {
            showMenu: false,
            user: null,
            notifications: []
        };
    },
    props: ["minNav", "textWhite"],
    created() {
        this.minNav;
    },
    mounted() {
        let user = localStorage.getItem('user');
        if(user !== null) this.user = JSON.parse(user);

        this.refreshBadge();
    },
    methods: {
        ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
        ...mapActions(["toggleSidebarColor"]),

        toggleSidebar() {
            this.toggleSidebarColor("bg-white");
            this.navbarMinimize();
        },

        refreshBadge() {
            axios.get(`/dashboard/listaNotifiche`)
                .then(res => {
                    if(!res) return null;
                    
                    const newNotifications = res.data.listaNotifiche.reduce((acc, notification) => {
                        return acc + (notification.status === 0 ? 1 : 0);
                    }, 0)

                    this.$store.dispatch('refreshNotificationsBadge', newNotifications)
                })
                .catch(() => null)
        },

        logout() {
            localStorage.clear();
            keycloak.logout({ redirectUri: `${ window.location.origin}/signin` });
        },
    },
    computed: {
        ...mapState(['refreshBotificationsBadge']),

        currentRouteName() {
            return this.$route.name;
        },
    }
};
</script>

<style scoped>
nav.navbar.navbar-main {
    height: 5rem;
    position: fixed;
    top: 5rem;
    right: 0;
    left: 17rem;
    z-index: 998;
}

@media screen and (max-width: 1199px) {
    nav.navbar.navbar-main {
        left: 0;
    }
}

div.position-relative i {
    color: #FFFFFF;
    font-size: 1.3rem;
}

div.badge {
    display: block;
    width: 1rem;
    height: 1rem;
    max-width: 1rem;
    max-height: 1rem;
    font-size: .6rem;
    z-index: 99;
}
</style>
