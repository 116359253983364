import axios from 'axios';

export const interceptor = () => {
	axios.interceptors.request.use(
		(config) => handleRequestConfig(config),

		(error) => Promise.reject(error)
	);

	axios.interceptors.response.use(
		handleResponse,
		handleResponseError
	);
};

function handleRequestConfig(config) {
	const jwtToken = localStorage.getItem('access-token');
	const absoluteURLRegex = /^(?:\w+:)\/\//;

	if (!absoluteURLRegex.test(config.url)) config.url = window.VUE_APP_BASE_URL.concat(config.url);
	if (jwtToken) config.headers['Authorization'] = `Bearer ${jwtToken}`;

	console.log('>>> fullPath:', config.url);

	return config;
}

function handleResponse(response) {
	// console.log('[RESPONSE]: ', response);

	const { errorCode, errorDescription } = response.data;
	if (typeof errorCode !== 'number' && response.status === 200) return response;

	switch (errorCode) {
		case 0:
			if (response.config.url.includes('login/access')) {
				localStorage.setItem('user', JSON.stringify(response.data.user));
			}
			break;

		default:
			return Promise.reject(new Error(errorDescription));
	}

	return response;
}

function handleResponseError(err) {
	// console.error('[ERROR]: ', err);

	let rejection = err.message;

	if (err.response) {
		const { status } = err.response;

		switch (status) {
			case 401:
				rejection = 'Il token è scaduto oppure non valido. Effettua una nuova login';
				break;

			case 403:
				rejection = 'Non sei autorizzato ad accedere a queste informazioni';
				break;

			case 404:
				rejection = 'La risorsa richiesta non è stata trovata';
				break;

			default:
				rejection = 'Si è verificato un errore nel server. Si prega di riprovare';
				break;
		}
	} else if (err.code === axios.AxiosError.ECONNABORTED) {
		rejection = 'Richiesta annullata';
	}
	
	return Promise.reject(new Error(rejection));
}
