<template>
    <Loader :active="isLoading" />

    <div class="collapse navbar-collapse w-auto h-auto h-100" id="sidenav-collapse-main">
        <ul class="navbar-nav">
            <li v-for="route in filteredRoutes" :key="route.name" class="nav-item">
                <div v-if="!route.children">
                    <SidenavItem
                        :url="route.path"
                        :class="getRoute === route.path.split('/')[1] ? 'active' : ''"
                        :navText="route.meta.breadcrumbs[0].text"
                    >
                        <template v-if="getRoute !== route.path.split('/')[1]" #icon>
                            <i class="fa-solid fa-circle text-sm clr-primary"></i>
                        </template>

                        <template v-if="route.meta.breadcrumbs[0].text === 'Approvazione Richieste' && $store.state.newRequests > 0" #badge>
                            <div class="ms-2 badge rounded-circle overflow-hidden d-flex justify-content-center align-items-center">
                                <span class="bg-danger font-weight-bolder text-xxs p-2">
                                    {{ $store.state.newRequests > 99 ? '+99' : $store.state.newRequests }}
                                </span>
                            </div>
                        </template>
                    </SidenavItem>
                </div>

                <details v-else :ref="getRefs(route.meta.breadcrumbs[0].text)">
                    <summary class="nav-link" @click.prevent="getToggler(route.meta.breadcrumbs[0].text)">
                        <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                            <span
                                v-if="getStoredState(route.meta.breadcrumbs[0].text)">
                                <i class="fa-solid fa-circle-minus text-sm clr-primary"></i>
                            </span>

                            <span v-else>
                                <i class="fa-solid fa-circle-plus text-sm clr-primary"></i>
                            </span>
                        </div>

                        <span class="nav-link-text text-sm ms-1">
                            {{ route.meta.breadcrumbs[0].text }}
                        </span>

                        <div class="ms-2 badge rounded-circle overflow-hidden d-flex justify-content-center align-items-center"> 
                            <span v-if="(
                                    route.meta.breadcrumbs[0].text === 'Gestione Istanze' ||
                                    route.meta.breadcrumbs[0].text === 'Istanze Assegnate' ||
                                    route.meta.breadcrumbs[0].text === 'Istanze'
                                ) && $store.state.newInstances > 0" class="bg-danger font-weight-bolder text-xxs p-2"
                            >
                                {{ $store.state.newInstances > 99 ? '+99' : $store.state.newInstances }}
                            </span>

                            <span v-if="route.meta.breadcrumbs[0].text === 'Gestione Utenti' && $store.state.newRequests > 0" class="bg-danger font-weight-bolder text-xxs p-2">
                                {{ $store.state.newRequests > 99 ? '+99' : $store.state.newRequests }}
                            </span>
                        </div>
                    </summary>

                    <div v-for="childRoute in route.children" :key="childRoute.name">
                        <SidenavItem
                            v-if="subRoutes(childRoute)"
                            :url="`${route.path}/${childRoute.path}`"
                            :class="navClasses(childRoute)"
                            :navText="navText(childRoute)"
                        />
                    </div>
                </details>
            </li>

            <li v-if="userGuest === 1" class="nav-item" title="Scarica il documento di delega caricato">
                <div class="cursor-pointer nav-link text-sm" @click.prevent="() => downloadDelegationDocument()">
                    <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                        <i class="fa-solid fa-circle-down text-sm clr-primary"></i>
                    </div>
    
                    <span class="nav-link-text ms-1">Documento Delega</span>
                </div>
            </li>

            <li v-if="mapsUrl && (userType === 3 || userType === 4)" class="nav-item">
                <div>
                    <a class="nav-link text-sm" :href="`${mapsUrl}`" target="_blank">
                        <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                            <i class="fa-solid fa-circle text-sm clr-primary"></i>
                        </div>
        
                        <span class="nav-link-text ms-1">Visualizza farmacie</span>
                    </a>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import axios from 'axios';
import Loader from '../../components/Loader.vue';
import { NO_ACTIONS_INSTANCES_BADGE_MAP, IGNORED_PATHS_MAP } from '../../global/constants';
import { routes as ROUTES } from '../../router/index';
import SidenavItem from "./SidenavItem.vue";
import { mapState } from 'vuex';

export default {
    name: "SidenavList",
    components: {
        SidenavItem,
        Loader
    },
    props: {
        cardBg: String
    },
    data() {
        return {
            isUserLogged: false,
            userType: null,
            mapsUrl: null,
            userGuest: null,
            isLoading: false
        };
    },
    mounted() {
        let jwtToken = localStorage.getItem('access-token')
        if (jwtToken !== null) this.isUserLogged = true;

        let user = localStorage.getItem('user');
        if(user !== null) {
            user = JSON.parse(user);
            this.userType = user.type;
            this.userGuest = user.flagUserGuest;
        }

        this.mapsUrl = window.VUE_APP_JUSTHEAL_URL

        this.refreshInstancesBadge();
        this.refreshRequestBadge();
    },
    methods: {
        showLoader() {
            this.isLoading = true;
        },
        
        hideLoader() {
            this.isLoading = false;
        },

        toggleInstanceMenu() {
            let menuState = this.$store.state.isInstanceMenuOpen;
            this.$store.dispatch('toggleInstanceMenu', !menuState)
        },

        toggleManagementMenu() {
            let menuState = this.$store.state.isManagementMenuOpen;
            this.$store.dispatch('toggleManagementMenu', !menuState)
        },

        toggleUserManagementMenu() {
            let menuState = this.$store.state.isUserManagementMenuOpen;
            this.$store.dispatch('toggleUserManagementMenu', !menuState);
        },

        toggleEntrustedInstancesMenu() {
            let menuState = this.$store.state.isEntrustedInstancesMenuOpen;
            this.$store.dispatch('toggleEntrustedInstancesMenu', !menuState);
        },

        refreshInstancesBadge() {
            axios.get(`/istanze/userInstances`)
                .then(res => {
                    let instancesNum = 0;
                    let noActionStates = [];

                    if(NO_ACTIONS_INSTANCES_BADGE_MAP[this.userType]) {
                        noActionStates = NO_ACTIONS_INSTANCES_BADGE_MAP[this.userType];
                        instancesNum = res.data.istanzeList.filter(instance => !noActionStates.includes(instance.statoCamunda)).length
                    } else {
                        instancesNum = res.data.istanzeList.length
                    };

                    this.$store.dispatch('setNewIstances', instancesNum);
                })
                .catch(() => null)
        },

        refreshRequestBadge() {
            axios.get(`/anagrafica/richieste`)
                .then(res => {
                    if(!res) return null;

                    const newRequests = res.data.listaAnagraficaUtenti.length;
                    this.$store.dispatch('setNewRequests', newRequests);
                })
                .catch(() => null)
        },

        downloadDelegationDocument() {
            this.showLoader();

            axios.get(`/document/downloadAutodichiarazione`, { responseType: 'blob' })
                .then(res => {
                    const href = URL.createObjectURL(res.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `DELEGA_${Date.now()}`);
                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                })
                .catch(err => this.$emit('handle-file-download-message', err.message))
                .finally(() => this.hideLoader())
        }
    },
    computed: {
        ...mapState([
            'isInstanceMenuOpen',
            'isManagementMenuOpen',
            'isUserManagementMenuOpen',
            'isEntrustedInstancesMenuOpen',
            'newRequests',
            'newInstances'
        ]),

        filterRoutesByUser() {
            let supportedRoutes = ROUTES;

            if(this.userType !== null) {
                supportedRoutes = supportedRoutes.filter(route => !IGNORED_PATHS_MAP[this.userType].includes(route.name));
            }

            if(this.userGuest === 1 && this.userType === 1) {
                supportedRoutes = supportedRoutes.filter(route => route.name !== 'ListDelegates');
            }

            return supportedRoutes;
        },

        filteredRoutes() {
            let sortedRoutes = [];
            const ignoredPaths = ['/', 'EditFarmacia', 'NotFound', 'SigninView', 'Signup', 'AslInvolvement', 'KeycloakCallback'];

            sortedRoutes = this.filterRoutesByUser
                .filter(route => !ignoredPaths.includes(route.name) && this.isUserLogged)
                .sort((a, b) => a.meta.order - b.meta.order)

            return sortedRoutes;
        },

        subRoutes() {
            return (route) => {
                if(route.name !== 'ReviewRequest' && route.name !== 'ReviewEntrustedRequest') return true;
                return false;
            }
        },

        navText() {
            return (route) => {
                if(route.name === 'NewRequest' && this.getRoute === 'review-request') return 'Modifica Istanza';
                if(route.name === 'EntrustedRequest' && this.getRoute === 'review-entrusted-request') return 'Verifica Istanza'
                return route.meta.breadcrumbs[0].text;
            }
        },

        navClasses() {
            return (route) => {
                if(route.name === 'NewRequest' && this.getRoute === 'review-request') return 'active';
                if(route.name === 'EntrustedRequest' && this.getRoute === 'review-entrusted-request') return 'active';
                if(this.getRoute === route.path.split('/')[1]) return 'active';
                return '';
            }
        },

        getRoute() {
            const routeArr = this.$route.path.split("/");
            if(routeArr.includes('review-request')) {
                return routeArr[routeArr.indexOf('review-request')]
            }
            if(routeArr.includes('review-entrusted-request')) {
                return routeArr[routeArr.indexOf('review-entrusted-request')]
            }
            return routeArr[1];
        },

        getRefs() {
            return (routeName) => {
                switch (routeName) {
                    case 'Istanze':
                        return 'instanceMenu';

                    case 'Gestione Istanze':
                        return 'managementMenu';

                    case 'Gestione Utenti':
                        return 'usersManagementMenu';
                    
                    case 'Istanze Assegnate':
                        return 'entrustedInstancesMenu'

                }
            }
        },

        getToggler() {
            return (routeName) => {
                switch (routeName) {
                    case 'Istanze':
                        return this.toggleInstanceMenu();

                    case 'Gestione Istanze':
                        return this.toggleManagementMenu();

                    case 'Gestione Utenti':
                        return this.toggleUserManagementMenu();

                    case 'Istanze Assegnate':
                        return this.toggleEntrustedInstancesMenu();

                }
            }
        },

        getStoredState() {
            return (routeName) => {
                switch (routeName) {
                    case 'Istanze':
                        return this.$store.state.isInstanceMenuOpen;

                    case 'Gestione Istanze':
                        return this.$store.state.isManagementMenuOpen;

                    case 'Gestione Utenti':
                        return this.$store.state.isUserManagementMenuOpen;

                    case 'Istanze Assegnate':
                        return this.$store.state.isEntrustedInstancesMenuOpen;
                }
            }
        }
    },
    watch: {
        isInstanceMenuOpen(newValue) {
            if (!this.$refs.instanceMenu) return null;

            if (!newValue) {
                this.$refs.instanceMenu[0].removeAttribute('open');
            } else {
                this.$refs.instanceMenu[0].setAttribute('open', '');
            }
        },

        isManagementMenuOpen(newValue) {
            if (!this.$refs.managementMenu) return null;

            if (!newValue) {
                this.$refs.managementMenu[0].removeAttribute('open');
            } else {
                this.$refs.managementMenu[0].setAttribute('open', '');
            }
        },

        isUserManagementMenuOpen(newValue) {
            if (!this.$refs.usersManagementMenu) return null;

            if (!newValue) {
                this.$refs.usersManagementMenu[0].removeAttribute('open');
            } else {
                this.$refs.usersManagementMenu[0].setAttribute('open', '');
            }
        },

        isEntrustedInstancesMenuOpen(newValue) {
            if (!this.$refs.entrustedInstancesMenu) return null;

            if (!newValue) {
                this.$refs.entrustedInstancesMenu[0].removeAttribute('open');
            } else {
                this.$refs.entrustedInstancesMenu[0].setAttribute('open', '');
            }
        },
    }
};
</script>

<style scoped>
i.fa-solid {
    top: 0;
}
div.badge {
    display: block;
    width: 1.3rem;
    height: 1.3rem;
    max-width: 1.3rem;
    max-height: 1.3rem;
    z-index: 10000;
}
details summary::-webkit-details-marker {
  display:none;
}
</style>
