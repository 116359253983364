<template>
    <Loader :active="isLoading" />

    <ArgonAlert
        icon="fa-solid fa-circle-exclamation text-white"
        :color="fetchError !== null ? 'danger' : 'success'"
        :isShown="isToastVisible"
        :dismissible="true"
        class="toastr col-2 text-sm position-fixed bottom-0 end-0"
        :class="isToastVisible ? 'active' : ''"
        @modalDismiss="handleToastDismiss()"
    >
        <template #modalText>
            <p v-if="fetchError" class="text-break fs-6 text-white mb-0">{{ fetchError }}</p>
            <p v-else class="text-break fs-6 text-white mb-0">L'operazione è stata completata con successo</p>
        </template>
    </ArgonAlert>

    <div class="container-fluid overflow-hidden">
        <div class="row mx-0 g-4">
            <!-- <div v-if="credenzialiData.length > 0" class="col-md-8">
                <GradientLineChart
                    id="report-farmacie"
                    :height="250"
                    :showLegend="true"
                    :legends="['Richieste']"
                    :labels="labels"
                    :data="credenzialiData"
                    title="Richieste Utenti"
                />
            </div> -->

            <!-- <div v-else class="col-md-8 align-self-center">
                <div class="card">
                    <div class="card-body">
                        <p class="fs-6 text-bold text-dark text-center mb-0">
                            Nessun dato trovato
                        </p>
                    </div>
                </div>
            </div> -->
            <div class="col-md-7 align-self-center">
                <DashboardCard v-if="user">
                    <template #cardHeader>
                        <h5 class="text-uppercase fw-normal text-dark fs-5">Anagrafica</h5>
                    </template>
                
                    <template #cardBody>      
                            <div class="card shadow-none d-flex h-100 flex-column justify-content-around lh-lg pe-0 user-info">
                                <div class="border-bottom">
                                    <span class="fs-6 fw-bold">Nome&colon;</span>
                                    <span class="fs-6">{{ user.nome }}</span>
                                </div>
            
                                <div class="border-bottom">
                                    <span class="fs-6 fw-bold">Cognome&colon;</span>
                                    <span class="fs-6">{{user.cognome}}</span>
                                </div>
            
                                <div class="border-bottom">
                                    <span class="fs-6 fw-bold">Cod. Fiscale&colon;</span>
                                    <span class="fs-6">{{ user.codiceFiscale}}</span>
                                </div>
            
                                <div class="border-bottom">
                                    <span class="fs-6 fw-bold">Email&colon;</span>
                                    <span class="fs-6">{{user.email}}</span>
                                </div>
                            </div>
                    </template>
                </DashboardCard>

                <div v-else class="card">
                    <div class="card-body">
                        <p class="fs-6 text-bold text-dark text-center mb-0">
                            Nessun dato trovato
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-5">
                <DashboardCard :class="notificationsList.length > 0 ? 'min-h-100' : '' ">
                    <template #cardHeader>
                        <h5 class="fs-5 text-uppercase text-dark fw-normal">Notifiche</h5>
                    </template>

                    <template #cardBody>
                        <div v-if="notificationsList.length > 0" class="row flex-column">
                            <div v-for="notification in notificationsList"
                                :key="notification.notificheId"
                                class="d-flex justify-content-start"
                            >

                                <div
                                    class="notification-bubble bubble w-90 mb-2 pb-1"
                                    :class="notification.status === 0 ? 'unread' : 'read'"
                                >
                                    <p class="mb-1 fs-6">{{ notification.description }}</p>
    
                                    <div class="text-xs text-dark text-end m-0 p-0 mt-2">{{notification.date}}</div>
                                </div>

                                <div v-if="notification.status === 0" class="w-10 text-end">
                                    <i
                                        role="button"
                                        title="Conferma lettura"
                                        class="fa-solid fa-circle-check text-lg"
                                        @click.prevent="handleNotificationRead(notification.notificheId)"
                                    ></i>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <p class="fs-6 text-dark font-weight-bold">
                                Nessuna notifica
                            </p>
                        </div>

                        <div v-if="isNotificationsListRead" class="text-center">
                            <p
                                class="text-dark font-weight-bold text-decoration-underline fs-6"
                                role="button"
                                @click.prevent="handleAllNotificationsRead()"
                            >Segna tutte come lette</p>
                        </div>
                    </template>
                </DashboardCard>
            </div>

            <div class="col-md-12 pb-3">
                <div class="row gap-3 gap-md-0">
                    <div v-if="donutChartValues.length > 0" class="col-md-5 mb-md-2">
                        <ConsumptionRoomChart
                            :height="100"
                            :labels="donutChartLabels"
                            :data="donutChartValues"
                            title="Stato Istanze"
                        />
                    </div>

                    <div v-else class="col-md-5 mb-md-2 align-self-center">
                        <div class="card">
                            <div class="card-body">
                                <p class="fs-6 text-bold text-dark text-center mb-0">
                                    Nessun dato trovato
                                </p>
                            </div>
                        </div>
                    </div>

                    <div v-if="istanzeData.length > 0" class="col-md-7">
                        <GradientLineChart
                            id="report-richieste"
                            :showLegend="true"
                            :height="175"
                            :legends="['Istanze']"
                            :labels="labels"
                            :data="istanzeData"
                            title="Istanze per Mese"
                        />
                    </div>

                    <div v-else class="col-md-7 align-self-center">
                        <div class="card">
                            <div class="card-body">
                                <p class="fs-6 text-bold text-dark text-center mb-0">
                                    Nessun dato trovato
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import Loader from '../components/Loader.vue';
import GradientLineChart from "../examples/Charts/GradientLineChart.vue";
import DashboardCard from '../components/DashboardCard.vue';
import ConsumptionRoomChart from '../examples/Charts/ConsumptionRoomChart.vue';
import ArgonAlert from '../components/ArgonAlert.vue';

const MONTHS_MAP = {
    1: 'Gen',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'Mag',
    6: 'Giu',
    7: 'Lug',
    8: 'Ago',
    9: 'Set',
    10: 'Ott',
    11: 'Nov',
    12: 'Dic',
};

export default {
    name: "DashboardAmministratori",
    components: {
        Loader,
        GradientLineChart,
        DashboardCard,
        ConsumptionRoomChart,
        ArgonAlert
    },
    data() {
        return {
            isLoading: false,
            isToastVisible: false,
            fetchError: null,
            userType: null,
            notificationsList: [],
            donutChartLabels: [
                'Istanze in corso',
                'Istanze rigettate',
                'Istanze concluse',
            ],
            donutChartValues: [],
            credenzialiData: [],
            istanzeData: [],
            user: null
        };
    },

    mounted() {
        this.refreshDashboard();
    }, 
    methods: {
        showLoader() {
            this.isLoading = true;
        },
                    
        hideLoader() {
            this.isLoading = false;
        },
                
        formatStringToDate(dateMillis) {
            const date = new Date(dateMillis)
            const year = date.getFullYear();
            const month = ((date.getMonth() + 1).toString()).padStart(2, '0');
            const day = (date.getDate().toString().padStart(2, '0'));
            const parsedDate = `${day}-${month}-${year}`;

            return parsedDate;
        },

        refreshDashboard() {
            this.showLoader();

            axios.get(`/dashboard/refresh`)
                .then(res => {
                    this.user = res.data.anagrafica;
                    this.notificationsList = res.data.listaNotifiche;
                    this.refreshNotificationsBadge();

                    this.$store.dispatch("setUser", res.data.anagrafica);
                    this.labels = res.data.kpiIstanze.map(istanza => MONTHS_MAP[istanza.mese]);
                    this.istanzeData = [res.data.kpiIstanze.map(istanza => istanza.value)];
                    this.credenzialiData = [res.data.kpiCredenziali.map(credenziale => credenziale.value)];

                    this.donutChartValues = [
                        res.data.istanzeInCorso,
                        res.data.istanzeEliminate,
                        res.data.istanzeConcluse
                    ];

                    this.hideLoader();
                })
                .catch(err => {
                    this.hideLoader();
                    this.fetchError = err.message;
                    this.isToastVisible = true;
                })
        },

        refreshNotificationsBadge() {
            const notifications = this.notificationsList.reduce((acc, notification) => {
                return acc + (notification.status === 0 ? 1 : 0);
            }, 0);

            this.$store.dispatch('refreshNotificationsBadge', notifications)
        },

        handleAllNotificationsRead() {
            this.showLoader();

            const data = { notificheList: this.notificationsList };

            axios.post(`/dashboard/confermaTutte`, data)
                .then(() => {
                    this.isToastVisible = true;
                    this.refreshDashboard();
                })
                .catch(err => {
                    this.hideLoader();
                    this.fetchError = err.message;
                    this.isToastVisible = true;
                })

        },

        handleNotificationRead(notificationId) {
            this.showLoader();

            const data = { notificaId: notificationId };

            axios.post(`/dashboard/confermaLettura`, data)
                .then(() => {
                    this.isToastVisible = true;
                    this.refreshDashboard();
                })
                .catch(err => {
                    this.hideLoader();
                    this.fetchError = err.message;
                    this.isToastVisible = true;
                })
        },

        handleToastDismiss() {
            this.isToastVisible = false;
            this.fetchError = null;
        }
    },
    computed: {
        ...mapState(['refreshBotificationsBadge']),

        isNotificationsListRead() {
            return this.notificationsList.some(notification => notification.status === 0);
        },
    }
};
</script>

<style scoped>
.user-info div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

h5 {
    margin-bottom: 0;
}

span {
    font-size: 1rem;
}

.notification-bubble {
    background-color: #EBEBEB;
    border-radius: .5rem;
    padding: 1em;
    font-size: .7rem;
    color: #333;
    position: relative;
}
</style>
