<template>
    <Transition name="modal">
        <ModalComponent :isSuccess="true" @close="$emit('dismiss')">
            <template #header>
                <div class="d-flex w-100 justify-content-between align-items-center">
                    <h5 class="fs-5 text-white text-uppercase fw-normal mb-0">Cookies</h5>
                    <i
                        class="fas fa-circle-xmark text-lg text-white cursor-pointer"
                        title="Ho letto e compreso"
                        @click="$emit('dismiss')"
                    ></i>
                </div>
            </template>

            <template #body>
                <section class="mt-2">
                    <h5 class="text-uppercase text-dark fw-normal">
                        Informativa Cookies
                    </h5>

                    <p class="fs-6">
                        Al fine di rendere i propri servizi il piu' possibile efficienti e semplici da utilizzare questo sito fa uso di cookies.
                        Pertanto, quando si visita il Sito, viene inserita una quantita' minima di informazioni nel dispositivo dell'utente, come piccoli file di testo chiamati "cookie",
                        che vengono salvati nella directory del browser Web dell'utente.
                        Esistono diversi tipi di cookie, ma sostanzialmente lo scopo principale di un cookie e' quello di far funzionare piu' efficacemente il Sito e di abilitarne determinate funzionalita'.
                        I cookie sono utilizzati per migliorare la navigazione globale dell'utente. In particolare:
                        Consentono di navigare in modo efficiente da una pagina all'altra del sito Web.
                        Memorizzano il nome utente e le preferenze inserite.
                        Consentono di evitare di inserire le stesse informazioni (come nome utente e password) piu' volte durante la visita.
                        Esistono vari tipi di cookie, di seguito si riporta l'unica tipologia di cookie qui utilizzata con una descrizione della finalita' legata all'uso:
                    </p>
                </section>

                <section>
                    <h5 class="text-uppercase text-dark fw-normal">
                        Cookie tecnici
                    </h5>

                    <p class="fs-6">
                        I cookie di questo tipo sono necessari per il corretto funzionamento di alcune aree del sito.
                        I cookie di questa categoria comprendono sia cookie persistenti che cookie di sessione.
                        In assenza di tali cookie, il sito o alcune porzioni di esso potrebbero non funzionare correttamente.
                        Pertanto, vengono sempre utilizzati, indipendentemente dalle preferenze dell'utente.
                        I cookie di questa categoria vengono sempre inviati dal nostro dominio.
                    </p>
                </section>
            </template>
        </ModalComponent>
    </Transition>
</template>

<script>
import ModalComponent from './modal/ModalComponent.vue';

export default {
    name: 'CookiesComponent',
    components: {
        ModalComponent
    }
}
</script>
