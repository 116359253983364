<template>
	<footer class="footer">
		<div class="container-fluid">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center footer-element">
                    <div class="img-container img-container-sm me-2">
                        <img src="../assets/img/logo_accreditamento.png" alt="Logo Sinfonia">
                    </div>

					<div class="text-xs copyright text-muted lh-1" :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-center'">
						<strong>SINFONIA &#169;</strong> {{ new Date().getFullYear() }} - Regione Campania
					</div>
				</div>

				<div class="footer-element d-flex justify-content-center">
					<div class="img-container">
                        <img src="../assets/img/logo_soresa.png" alt="Logo Soresa">
                    </div>
				</div>

				<div class="footer-element">
					<a
                        class="text-xs copyright text-muted fw-bold text-decoration-underline"
                        :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-center'"
                        href="https://www.regione.campania.it/regione/it/privacy/privacy"
                        target="_blank"
                    >Privacy Policy</a>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: 'app-footer',
};
</script>

<style scoped>
    footer.footer {
        display: flex;
        align-items: center;
        height: 2rem;
        background-color: #FFFFFF;
    }

    .footer-element {
        flex: 1;
        min-width: 100px;
    }

    .footer-element:last-child {
        text-align: right;
    }

    .img-container {
        width: 6rem;
    }

    .img-container-sm {
        width: 3rem;
    }

    .img-container img {
        width: 100%;
        display: block;
        object-fit: contain;
    }
</style>
