<template>
    <transition name="modal">
        <ModalComponent>
            <template #header>Elimina Istanza</template>

            <template #body>
                <div class="d-flex flex-column justify-content-between pt-3">
                    <p class="fs-6 text-bold text-dark">
                        Stai per eliminare l'istanza: "{{ instanceDetails.descrizione }}", richiesta in data {{ formatStringToDate(instanceDetails.dataUltimaModifica) }}. <br />
                        L'eliminazione è definitiva. Desideri confermare?
                    </p>
                </div>
            </template>

            <template #footer>
                <ArgonButton
                    color="primary"
                    class="ms-auto mx-1 py-2 px-3 bg-dark fs-6"
                    size="sm"
                    @click.prevent="$emit('dismiss')"
                >Annulla</ArgonButton>
            
                <ArgonButton
                    class="ms-auto mx-1 py-2 px-3 bg-danger fs-6"
                    color="danger"
                    size="sm"
                    @click.prevent="deleteInstance()"
                >Elimina</ArgonButton>
            </template>
        </ModalComponent>
    </transition>
</template>

<script>
import axios from 'axios';
import ModalComponent from '../ModalComponent.vue';
import ArgonButton from '../../ArgonButton.vue';

export default {
    name: 'DeleteModalComponent',
    components: {
        ModalComponent,
        ArgonButton,
    },
    props: ['instanceDetails'],
    emits: [
        'show-loader',
        'hide-loader',
        'handle-instance-deleted',
        'handle-toast',
        'dismiss'
    ],
    methods: {
        deleteInstance() {
            this.$emit('show-loader');

            const data = {
                istanzaId: this.instanceDetails.istanzaId
            };

            axios.post(`/istanze/deleteInstance`, data)
                .then(() => this.$emit('handle-instance-deleted'))
                .catch(err => {
                    this.$emit('hide-loader');
                    this.$emit('handle-toast', err.message);
                })
        },

        formatStringToDate(dateMillis) {
            let date = new Date(dateMillis);
            let year = date.getFullYear();
            let month = ((date.getMonth() + 1).toString()).padStart(2, '0');
            let day = (date.getDate().toString().padStart(2, '0'));
            let parsedDate = `${day}-${month}-${year}`;

            return parsedDate;
        },
    }
}
</script>
