import { createStore } from "vuex";

export default createStore({
	state: {
		hideConfigButton: false,
		isPinned: true,
		showConfig: false,
		sidebarType: 'bg-white',
		isRTL: false,
		mcolor: '',
		darkMode: false,
		isNavFixed: false,
		isAbsolute: false,
		showNavs: true,
		showSidenav: true,
		showNavbar: true,
		showFooter: true,
		showMain: true,
		layout: 'default',
		isInstanceMenuOpen: false,
		isManagementMenuOpen: false,
		isUserManagementMenuOpen: false,
		isEntrustedInstancesMenuOpen: false,
		newInstances: 0,
		newRequests: 0,
		notifications: 0,
		user: null
	},
	mutations: {
		toggleConfigurator(state) {
			state.showConfig = !state.showConfig;
		},
		navbarMinimize(state) {
			const sidenav_show = document.querySelector('.g-sidenav-show');
			const sidenavClassList = sidenav_show.classList;

			if (sidenavClassList.length === 1 || sidenavClassList.contains('g-sidenav-hidden')) {
				sidenavClassList.remove('g-sidenav-hidden');
				sidenavClassList.add('g-sidenav-pinned');
				state.isPinned = true;
			} else {
				sidenavClassList.add('g-sidenav-hidden');
				sidenavClassList.remove('g-sidenav-pinned');
				state.isPinned = false;
			}
		},
		sidebarType(state, payload) {
			state.sidebarType = payload;
		},
		navbarFixed(state) {
			if (state.isNavFixed === false) {
				state.isNavFixed = true;
			} else {
				state.isNavFixed = false;
			}
		},
		instanceMenu(state, payload) {
			state.isInstanceMenuOpen = payload;
		},
		managementMenu(state, payload) {
			state.isManagementMenuOpen = payload;
		},
		userManagementMenu(state, payload) {
			state.isUserManagementMenuOpen = payload;
		},
		entrustedInstancesMenu(state, payload) {
			state.isEntrustedInstancesMenuOpen = payload;
		},
		newInstances(state, payload) {
			state.newInstances = payload;
		},
		newRequests(state, payload) {
			state.newRequests = payload;
		},
		notifications(state, payload) {
			state.notifications = payload;
		},
		user(state, payload) {
			state.user = payload;
		}
	},
	actions: {
		toggleSidebarColor({ commit }, payload) {
			commit('sidebarType', payload);
		},
		toggleInstanceMenu({ commit }, payload) {
			commit('instanceMenu', payload);
		},
		toggleManagementMenu({ commit }, payload) {
			commit('managementMenu', payload);
		},
		toggleUserManagementMenu({ commit }, payload) {
			commit('userManagementMenu', payload);
		},
		toggleEntrustedInstancesMenu({ commit }, payload) {
			commit('entrustedInstancesMenu', payload);
		},
		setNewIstances({ commit }, payload) {
			commit('newInstances', payload);
		},
		setNewRequests({ commit }, payload) {
			commit('newRequests', payload);
		},
		refreshNotificationsBadge({ commit }, payload) {
			commit('notifications', payload);
		},
		setUser({ commit }, payload) {
			commit('user', payload);
		}
	},
	getters: {},
});
