import { createRouter, createWebHistory } from 'vue-router';
import createStore from '../store/index';
import Dashboard from '../views/Dashboard.vue';
import DashboardAmministratori from '../views/DashboardAmministratori.vue';
// import AslInvolvement from '../views/asl/AslInvolvement.vue';
// import KeycloakCallback from '../views/KeycloakCallback.vue';
import { USER_ALLOWED_PATHS_MAP } from '../global/constants.js';
import Signin from '../views/Signin.vue';

const STORES = () => import(/* webpackChunkName: "Stores" */ '../views/Stores.vue');

const REQUESTS = () => import(/* webpackChunkName: "Requests" */ '../views/requests/Request.vue');
const NEW_REQUEST = () => import(/* webpackChunkName: "NewRequest" */ '../views/requests/components/NewRequest.vue');
const IN_PROGRESS_REQUESTS = () => import(/* webpackChunkName: "InProgressRequests" */ '../views/requests/components/InProgressRequests.vue');
const REQUESTS_ARCHIVE = () => import(/* webpackChunkName: "RequestsArchive" */ '../views/requests/components/RequestsArchive.vue');

//const REPORTS = () => import(/* webpackChunkName: "Reports" */ '../views/Reports.vue');
const PROFILE = () => import(/* webpackChunkName: "Profile" */ '../views/Profile.vue');
const EDIT_FARMACIA = () => import(/* webpackChunkName: "EditFarmacia" */ '../views/edit-farmacia/EditFarmacia.vue');
const ADD_FAMRACIA = () => import(/* webpackChunkName: "AddFarmacia" */ '../views/edit-farmacia/AddFarmacia.vue');

const USERS_MANAGEMENT = () => import(/* webpackChunkName: "UserManagement" */ '../views/user-management/UsersManagement.vue');
// const MANAGE_USERS = () => import(/* webpackChunkName: "ManageUsers" */ '../views/user-management/components/ManageUsers.vue');
// const REPORTS_APPROVAL = () => import(/* webpackChunkName: "RequestsApproval" */ '../views/ReportsApproval.vue');
const ACTIVE_REQUESTS = () => import(/* webpackChunkName: "RequestsApproval" */ '../views/requests/components/ActiveRequests.vue');

const LIST_DELEGATES = () => import(/* webpackChunkName: "DelegatesList" */ '../views/ListDelegates.vue');
// const ADD_DELEGATE = () => import(/* webpackChunkName: "DelegatesList" */ '../views/AddDelegate.vue');

// const SIGNIN = () => import(/* webpackChunkName: "Signin" */ '../views/Signin.vue');
// const SIGNUP = () => import(/* webpackChunkName: "Signup" */ '../views/Signup.vue');
const NOT_FOUND = () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue');
const USER_SELF_DECLARATION = () => import(/* webpackChunkName: "UserSelfDeclaration" */ '../views/UserSelfDeclaration.vue');
const KEYCLOAK_CALLBACK = () => import(/* webpackChunkName: "KeycloakCallback"*/ '../views/KeycloakCallback.vue');

const STATE = createStore.state;
const INSTANNCE_SUB_MENU = ['NewRequest', 'ReviewRequest', 'InProgressRequests', 'RequestsArchive'];
const MANAGEMENT_SUB_MENU = ['InstancesSorting', 'ActiveInstances', 'InstancesArchive'];
// const USER_MANAGEMENT_SUB_MENU = ['ManageUsers', 'RequestsApproval'];
const ENTRUSTED_INSTANCES_SUB_MENU = ['EntrustedRequest', 'ReviewEntrustedRequest', 'EntrustedRequestsArchive'];

function isUserLogged() {
    const jwtToken = localStorage.getItem('access-token');
    return jwtToken !== null;
}

function isRedirectNeeded(to) {
    if(to.name === 'SigninView' || to.name === 'KeycloakCallback') return false;
    return true;
}

function beforeRedirect(to, from) {
    const isAuthorized = isUserLogged();
	if ((isAuthorized && from && from.name === 'UserSelfDeclaration') || (!isAuthorized && !isRedirectNeeded(to))) return true;
    if(!isAuthorized) return '/signin';

	if (isAuthorized && localStorage.getItem('user')) {
		const { type } = JSON.parse(localStorage.getItem('user'));

		if (!USER_ALLOWED_PATHS_MAP[type].includes(to.name)) {
			return from.path;
		}
	}

	// CONTROL INSTANCES SUB MENU
    if(!INSTANNCE_SUB_MENU.includes(to.name)) {
        if(STATE.isInstanceMenuOpen) createStore.dispatch('toggleInstanceMenu', false);
    } else {
        if(!STATE.isInstanceMenuOpen) createStore.dispatch('toggleInstanceMenu', true);
    }

    // CONTROL INSTANCE MANAGEMENT SUB MENU
    if(!MANAGEMENT_SUB_MENU.includes(to.name)) {
        if(STATE.isManagementMenuOpen) createStore.dispatch('toggleManagementMenu', false);
    } else {
        if(!STATE.isManagementMenuOpen) createStore.dispatch('toggleManagementMenu', true);
    }

    // CONTROL USER MANAGEMENT SUB MENU
    // if(!USER_MANAGEMENT_SUB_MENU.includes(to.name)) {
    //     if(STATE.isUserManagementMenuOpen) createStore.dispatch('toggleUserManagementMenu', false);
    // } else {
    //     if(!STATE.isUserManagementMenuOpen) createStore.dispatch('toggleUserManagementMenu', true);
    // }

    // CONTROL ENTRUSTED INSTANCES SUB MENU
    if(!ENTRUSTED_INSTANCES_SUB_MENU.includes(to.name)) {
        if(STATE.isEntrustedInstancesMenuOpen) createStore.dispatch('toggleEntrustedInstancesMenu', false);
    } else {
        if(!STATE.isEntrustedInstancesMenuOpen) createStore.dispatch('toggleEntrustedInstancesMenu', true);
    }

    const mustRedirect = isRedirectNeeded(to);
    return mustRedirect;
}

function redirectToCorrectDashboard() {
    let user = localStorage.getItem('user');

    if(user !== null) {
        user = JSON.parse(user);
        
        if(user.type === 1) {
            return '/dashboard-farmacia';
        } else {
            return '/dashboard-amministratori';
        }
    }

    return '/signin'
}

export const routes = [
	{
		path: '/',
		name: '/',
		redirect: () => redirectToCorrectDashboard(),
	},

	// CALLBACK SPID
	{
		path: '/keycloak-callback',
		name: 'KeycloakCallback',
		component: KEYCLOAK_CALLBACK,
		meta: {
			order: 30,
			breadcrumbs: [
				{
					text: 'Callback',
					to: { name: 'KeycloakCallback' },
				},
			],
			title: 'Callback',
		},
	},

	// DASHBOARDS
	{
		path: '/dashboard-farmacia',
		name: 'Dashboard',
		component: Dashboard,
		beforeEnter: (to, from) => beforeRedirect(to, from),
		meta: {
			order: 1,
			breadcrumbs: [
				{
					text: 'Dashboard',
					to: { name: 'Dashboard' },
				},
			],
			title: 'Dashboard',
		},
	},
	{
		path: '/dashboard-amministratori',
		name: 'DashboardAmministratori',
		component: DashboardAmministratori,
		beforeEnter: (to, from) => beforeRedirect(to, from),
		meta: {
			order: 2,
			breadcrumbs: [
				{
					text: 'Amministrazione',
					to: { name: 'DashboardAmministratori' },
				},
			],
			title: 'Amministrazione',
		},
	},

	// FARMACIA
	{
		path: '/pharmacies-list',
		name: 'ListaFarmacie',
		component: STORES,
		beforeEnter: (to, from) => beforeRedirect(to, from),
		meta: {
			order: 3,
			breadcrumbs: [
				{
					text: 'Lista Farmacie',
					to: { name: 'ListaFarmacie' },
				},
			],
			title: 'Lista Farmacie',
		},
	},
	{
		path: '/pharmacies-list/pharmacy/add-store',
		name: 'AddFarmacia',
		component: ADD_FAMRACIA,
		beforeEnter: (to, from) => beforeRedirect(to, from),
		meta: {
			order: 4,
			breadcrumbs: [
				{
					text: 'Aggiungi Farmacia',
					to: { name: 'AddFarmacia' },
				},
			],
			title: 'Aggiungi Farmacia',
		},
	},
	{
		path: '/pharmacies-list/pharmacy/edit/:id(\\d+)',
		name: 'EditFarmacia',
		component: EDIT_FARMACIA,
		props: true,
		beforeEnter: (to, from) => beforeRedirect(to, from),
		meta: {
			order: 5,
			breadcrumbs: [
				{
					text: 'Lista Farmacie',
					to: { name: 'ListaFarmacie' },
				},
				{
					text: 'Modifica Farmacia',
					to: { name: 'EditFarmacia' },
				},
			],
			title: 'Modifica dettagli farmacia',
		},
	},

	// ISTANZE
	{
		path: '/requests',
		name: 'Request',
		component: REQUESTS,
		beforeEnter: (to, from) => beforeRedirect(to, from),
		meta: {
			order: 6,
			breadcrumbs: [
				{
					text: 'Istanze',
					to: { name: 'Dashboard' },
				},
			],
		},
		children: [
			{
				path: 'new-request',
				name: 'NewRequest',
				component: NEW_REQUEST,
				beforeEnter: (to, from) => beforeRedirect(to, from),
				meta: {
					breadcrumbs: [
						{
							text: 'Inserisci Nuova',
							to: { name: 'NewRequest' },
						},
					],
					title: 'Nuova Istanza',
				},
			},
			{
				path: 'review-request/:action(\\bedit|view|review\\b)/:instanceTypeId(\\d+)?/:instanceId(\\d+)?',
				name: 'ReviewRequest',
				component: NEW_REQUEST,
				beforeEnter: (to, from) => beforeRedirect(to, from),
				props: true,
				meta: {
					breadcrumbs: [
						{
							text: 'Modifica istanza',
							to: { name: 'ReviewRequest' },
						},
					],
					title: 'Nuova Istanza',
				},
			},
			{
				path: 'in-progress-requests',
				name: 'InProgressRequests',
				component: IN_PROGRESS_REQUESTS,
				beforeEnter: (to, from) => beforeRedirect(to, from),
				meta: {
					breadcrumbs: [
						{
							text: 'Istanze in Corso',
							to: { name: 'InProgressRequests' },
						},
					],
					title: 'Lista istanze in corso',
				},
			},
			{
				path: 'requests-archive',
				name: 'RequestsArchive',
				component: REQUESTS_ARCHIVE,
				beforeEnter: (to, from) => beforeRedirect(to, from),
				meta: {
					breadcrumbs: [
						{
							text: 'Archivio Istanze',
							to: { name: 'RequestsArchive' },
						},
					],
					title: 'Archivio istanze',
				},
			},
		],
	},

	// ISTANZE ASSEGNATE
	{
		path: '/requests',
		name: 'EntrustedRequests',
		component: REQUESTS,
		beforeEnter: (to, from) => beforeRedirect(to, from),
		meta: {
			order: 7,
			breadcrumbs: [
				{
					text: 'Istanze Assegnate',
					to: { name: 'Dashboard' },
				},
			],
		},
		children: [
			{
				path: 'entrusted-request',
				name: 'EntrustedRequest',
				component: IN_PROGRESS_REQUESTS,
				beforeEnter: (to, from) => beforeRedirect(to, from),
				meta: {
					breadcrumbs: [
						{
							text: 'Istanze in Corso',
							to: { name: 'InProgressRequests' },
						},
					],
					title: 'Istanze Assegnate',
				},
			},
			{
				path: 'review-entrusted-request/:action(\\bedit|view|review\\b)/:instanceTypeId(\\d+)/:instanceId(\\d+)',
				name: 'ReviewEntrustedRequest',
				component: NEW_REQUEST,
				beforeEnter: (to, from) => beforeRedirect(to, from),
				props: true,
				meta: {
					breadcrumbs: [
						{
							text: 'Verifica Istanza',
							to: { name: 'ReviewEntrustedRequest' },
						},
					],
					title: 'Verifica Istanza',
				},
			},
			{
				path: 'entrusted-requests-archive',
				name: 'EntrustedRequestsArchive',
				component: REQUESTS_ARCHIVE,
				beforeEnter: (to, from) => beforeRedirect(to, from),
				meta: {
					breadcrumbs: [
						{
							text: 'Archivio Istanze',
							to: { name: 'EntrustedRequestsArchive' },
						},
					],
					title: 'Archivio Istanze',
				},
			},
		],
	},
	// {
	// 	path: '/reports',
	// 	name: 'Reports',
	// 	component: REPORTS,
	// 	beforeEnter: (to, from) => beforeRedirect(to, from),
	// 	meta: {
	// 		order: 8,
	// 		breadcrumbs: [
	// 			{
	// 				text: 'Reportistica',
	// 				to: { name: 'Reports' },
	// 			},
	// 		],
	// 		title: 'Reportistica',
	// 	},
	// },

	// INSTANCE MANAGEMENT - AMM GENERALE
	{
		path: '/instance-management',
		name: 'InstanceManagement',
		component: USERS_MANAGEMENT,
		beforeEnter: (to, from) => beforeRedirect(to, from),
		meta: {
			order: 9,
			breadcrumbs: [
				{
					text: 'Gestione Istanze',
					to: { name: 'InstanceManagement' },
				},
			],
			title: 'Gestione Istanze',
		},
		children: [
			{
				path: 'istances-sorting',
				name: 'InstancesSorting',
				component: IN_PROGRESS_REQUESTS,
				beforeEnter: (to, from) => beforeRedirect(to, from),
				meta: {
					breadcrumbs: [
						{
							text: 'Smistamento Istanze',
							to: { name: 'InstancesSorting' },
						},
					],
					title: 'Smistamento Istanze',
				},
			},
			{
				path: 'active-istances',
				name: 'ActiveInstances',
				component: ACTIVE_REQUESTS,
				beforeEnter: (to, from) => beforeRedirect(to, from),
				meta: {
					breadcrumbs: [
						{
							text: 'Istanze Smistate',
							to: { name: 'ActiveInstances' },
						},
					],
					title: 'Istanze Smistate',
				},
			},
			{
				path: 'requests-archive',
				name: 'InstancesArchive',
				component: REQUESTS_ARCHIVE,
				beforeEnter: (to, from) => beforeRedirect(to, from),
				meta: {
					breadcrumbs: [
						{
							text: 'Istanze Archiviate',
							to: { name: 'RequestsArchive' },
						},
					],
					title: 'Istanze Archiviate',
				},
			},
		],
	},

	// USER MANAGEMENT
	// {
	// 	path: '/users-management',
	// 	name: 'UsersManagement',
	// 	component: USERS_MANAGEMENT,
	// 	beforeEnter: (to, from) => beforeRedirect(to, from),
	// 	meta: {
	// 		order: 10,
	// 		breadcrumbs: [
	// 			{
	// 				text: 'Gestione Utenti',
	// 				to: { name: 'UsersManagement' },
	// 			},
	// 		],
	// 		title: 'Gestione Utenti',
	// 	},
	// 	children: [
	// 		{
	// 			path: 'manage-users',
	// 			name: 'ManageUsers',
	// 			component: MANAGE_USERS,
	// 			beforeEnter: (to, from) => beforeRedirect(to, from),
	// 			meta: {
	// 				breadcrumbs: [
	// 					{
	// 						text: 'Richieste Credenziali',
	// 						to: { name: 'ManageUsers' },
	// 					},
	// 				],
	// 				title: 'richieste credenziali',
	// 			},
	// 		},
	// 	],
	// },

	// {
	// 	path: '/requests-approval',
	// 	name: 'RequestsApproval',
	// 	component: REPORTS_APPROVAL,
	// 	beforeEnter: (to, from) => beforeRedirect(to, from),
	// 	meta: {
	// 		breadcrumbs: [
	// 			{
	// 				text: 'Approvazione Richieste',
	// 				to: { name: 'RequestsApproval' },
	// 			},
	// 		],
	// 		title: 'Approvazione richieste',
	// 	},
	// },

	// PROFILE
	{
		path: '/profile',
		name: 'Profile',
		component: PROFILE,
		beforeEnter: (to, from) => beforeRedirect(to, from),
		meta: {
			order: 11,
			breadcrumbs: [
				{
					text: 'Profilo',
					to: { name: 'Profile' },
				},
			],
			title: 'Dettagli profilo',
		},
	},

	// SIGNIN - SIGNUP
	{
		path: '/signin',
		name: 'SigninView',
		component: Signin,
		beforeEnter: (to, from) => beforeRedirect(to, from),
		meta: {
			order: 12,
			breadcrumbs: [
				{
					text: 'Signin',
					to: { name: 'SigninView' },
				},
			],
			title: 'Login',
		},
	},

	// AGGIUNTA DELEGATI

	{
		path: '/list-delegates',
		name: 'ListDelegates',
		component: LIST_DELEGATES,
		beforeEnter: (to, from) => beforeRedirect(to, from),
		meta: {
			order: 13,
			breadcrumbs: [
				{
					text: 'Lista Delegati',
					to: { name: 'ListDelegates' },
				},
			],
			title: 'Lista Delegati',
		},
	},
	// {
	// 	path: '/list-delegates/add-delegate',
	// 	name: 'AddDelegate',
	// 	component: ADD_DELEGATE,
	// 	beforeEnter: (to) => beforeRedirect(to),
	// 	meta: {
	// 		breadcrumbs: [
	// 			{
	// 				text: 'Invita Delegato',
	// 				to: { name: 'AddDelegate' },
	// 			},
	// 		],
	// 		title: 'Invita Delegato',
	// 	},
	// },

	// {
	// 	path: '/signup',
	// 	name: 'Signup',
	// 	component: SIGNUP,
	// 	beforeEnter: (to) => beforeRedirect(to),
	// 	meta: {
	// 		order: 13,
	// 		breadcrumbs: [
	// 			{
	// 				text: 'Signup',
	// 				to: { name: 'Signup' },
	// 			},
	// 		],
	// 		title: 'Registrazione',
	// 	},
	// },

	// USER DECLERATION
	{
		path: '/user-declaration',
		name: 'UserSelfDeclaration',
		component: USER_SELF_DECLARATION,
		beforeEnter: (to) => beforeRedirect(to),
		meta: {
			breadcrumbs: [
				{
					text: 'Autodichiarazione/Delega',
					to: { name: 'UserSelfDeclaration' },
				},
			],
			title: 'Autodichiarazione/Delega',
		},
	},

	// ASL INVOLVEMENT
	// {
	// 	path: '/asl/:token',
	// 	sensitive: true,
	// 	name: 'AslInvolvement',
	// 	component: AslInvolvement,
	// 	props: true,
	// 	meta: {
	// 		breadcrumbs: [
	// 			{
	// 				text: 'Coinvolgimento Asl',
	// 				to: { name: 'Signin' },
	// 			},
	// 		],
	// 		title: 'Coinvolgimento ASL',
	// 	},
	// },

	// PATH NOT FOUND
	{
		path: '/:catchAll(.*)',
		name: 'NotFound',
		component: NOT_FOUND,
		meta: {
			breadcrumbs: [
				{
					text: 'NotFound',
					to: { name: 'NotFound' },
				},
			],
			title: 'Pagina non trovata',
		},
	},
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: 'active',
});

export default router;
