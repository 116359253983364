<template>
	<img :src="imageUrl" :alt="imgAlt" @error="handleImageError" />
</template>

<script>
export default {
	name: 'ImageWithDefault',
	props: {
		defaultImagePath: {
			required: true,
			type: String,
		},
		imgAlt: {
			required: false,
			default: 'Immagine',
			type: String,
		},
		imageUrl: {
			required: true,
			type: [String, null],
		},
	},
	methods: {
		handleImageError(event) {
			event.target.src = this.defaultImagePath;
		},
	},
};
</script>

<style scoped>
img {
	aspect-ratio: 1;
}
</style>
