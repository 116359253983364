<template>
    <div class="card shadow-lg">
        <div class="card-header text-lg py-3 px-4 mb-2">
            <slot name="cardHeader"></slot>
        </div>

        <div class="card-body pt-0">
            <slot name="cardBody"></slot>
        </div>
    </div>
</template>

<style scoped>
.card {
    overflow: hidden;
    height: 20.5rem;
}

.card .card-body {
    background-color: white;
    overflow-y: auto;
}
@media screen and (width >= 1620px) {
    .card {
        overflow: hidden;
        height: 22rem;
    }
}
@media screen and (width <= 997px) {
    .card {
        overflow: hidden;
        height: 18rem;
    }
}
</style>
