<template>
    <div class="card shadow-lg">
        <div v-if="title" class="card-header pb-1">
            <h5 class="fs-5 text-uppercase fw-normal text-dark">{{ title }}</h5>
        </div>
        
        <div class="p-3 pt-1 card-body">
            <div class="row align-items-center">
                <div class="text-center col-4">
                    <div class="chart">
                        <canvas id="chart-consumption" class="chart-canvas" height="179"></canvas>
                    </div>
                </div>

                <div class="col-8">
                    <div class="table-responsive">
                        <table class="table mb-0 align-items-center">
                            <tbody>
                                <tr v-for="([label, data], i) in mapLabelsValues" :key="label">
                                    <td>
                                        <div class="px-2 py-0 d-flex">
                                            <span class="badge me-2" :style="`background-color: ${backgroundColor[i]}`">&nbsp;</span>
                                            <div class="d-flex flex-column justify-content-center">
                                                <h6 class="mb-0 fs-6"> {{ label }} </h6>
                                            </div>
                                        </div>
                                    </td>

                                    <td class="text-center align-middle">
                                        <span class="fs-6 font-weight-bold"> {{ data }} </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from "chart.js/auto";

const CHART_TICS = { display: false };

const CHART_XY_AXES = {
    grid: {
        drawBorder: false,
        display: false,
        drawOnChartArea: false,
        drawTicks: false,
    },
    ticks: CHART_TICS,
};

export default {
    name: "consumption-by-room-chart",
    props: {
        height: {
            type: Number,
            default: 197
        },
        labels: {
            type: Array,
            default: () => ["Living Room", "Kitchen", "Attic", "Garage", "Basement"]
        },
        data: {
            type: Array,
            default: () => [15, 20, 13, 32, 20]
        },
        title: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            backgroundColor: [
                '#BAE8C5',
                '#E25959',
                '#326798',
            ]
        }
    },
    mounted() {
        var ctx1 = document.getElementById("chart-consumption").getContext("2d");
        var gradientStroke1 = ctx1.createLinearGradient(0, 230, 0, 50);

        gradientStroke1.addColorStop(1, "rgba(203,12,159,0.2)");
        gradientStroke1.addColorStop(0.2, "rgba(72,72,176,0.0)");
        gradientStroke1.addColorStop(0, "rgba(203,12,159,0)");

        new Chart(ctx1, {
            type: "doughnut",
            data: {
                labels: this.$props.labels,
                datasets: [
                    {
                        weight: 1,
                        cutout: 25,
                        tension: 0.9,
                        pointRadius: 2,
                        borderWidth: 2,
                        backgroundColor: this.backgroundColor,
                        hoverOffset: 10,
                        data: this.$props.data,
                        fill: false,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                interaction: {
                    intersect: true,
                    mode: "index",
                },
                layout: {
                    padding: 5
                },
                scales: {
                    y: CHART_XY_AXES,
                    x: CHART_XY_AXES,
                },
            },
        });
    },
    computed: {
        mapLabelsValues() {
            const labels = this.$props.labels;
            const values = this.$props.data;
            const map = new Map();

            for(let i = 0; i < labels.length; i++) {
                map.set(labels[i], values[i]);
            };

            return map;
        }
    }
};
</script>
