<template>
  <nav aria-label="breadcrumb">
    <ol
      class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb"
      :class="`${this.$store.state.isRTL ? '' : ' me-sm-6'} ${this.$store.state.isNavFixed ? 'text-dark' : 'text-white' }`"
    >
      <li class="text-sm breadcrumb-item">
        <a
          v-if="this.$store.state.isRTL"
          class="opacity-5 ps-2"
          href="#"
          :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
          >لوحات القيادة</a
        >
        <router-link v-else
          :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
          class="opacity-8 text-decoration-underline fs-6"
          :to="userType && userType === 1 ? { name: 'Dashboard' } : { name: 'DashboardAmministratori' }"
        > Gestione Istanze </router-link>
      </li>
      <li
        v-for="crumb in findRoutePath" :key="crumb.text"
        class="text-sm breadcrumb-item active fs-6"
        :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
        aria-current="page"
      >
          <span
            class="text-white fs-6"
            :to="{ name: crumb.to.name }"
          > {{ crumb.text }} </span>
      </li>
      <!-- <li
        class="text-sm breadcrumb-item active"
        :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
        aria-current="page"
      >
        {{ currentPage }} -  {{ findRoutePath }}
      </li> -->
    </ol>
    <!-- <h6 class="mb-0 font-weight-bolder" :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'">
      {{ getRouteName }}
    </h6> -->
  </nav>
</template>

<script>
export default {
  name: "BreadcrumbsComponent",
  props: {
    currentPage: {
      required: true
    },
    textWhite: {
      type: String
    }
  },
  computed: {
    findRoutePath() {
      let crumbs = [];
      if (this.$route.matched[0]) {
        this.$route.matched.forEach(route => {
          const crumb = route.meta.breadcrumbs.map(crumb => crumb)[0];
          crumbs.push(crumb)
        });
      }

      return crumbs
    },

    getRouteName() {
      let current = '';
      
      if (this.$route.matched[0]) {
        const breadcrumbs = this.$route.matched[0].meta.breadcrumbs;
        current = breadcrumbs[breadcrumbs.length - 1].text;
      }

      return current
    },

    userType() {
      let user = localStorage.getItem('user');
      if(!user) return null;
      return JSON.parse(user).type;
    }
  }
};
</script>
