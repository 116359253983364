<template>
	<ArgonAlert
		class="col-2 toastr text-sm position-fixed bottom-0 end-0"
		icon="fa-solid fa-circle-exclamation text-white"
		color="danger"
		:isShown="isToastVisible"
		:dismissible="true"
		:class="isToastVisible ? 'active' : ''"
		@modalDismiss="handleToastDismiss()"
	>
		<template #modalText>
			<p class="text-break fs-6 text-white mb-0">{{ errorDescription }}</p>
		</template>
	</ArgonAlert>

	<CookiesComponent
		v-if="isCookiesModalVisible"
		@dismiss="handleCookiesDismiss()"
	/>

	<div
		v-if="!isCookiesModalVisible && isCookieRead === false"
		class="cookie-banner"
	>
		<div class="card">
			<div class="card-body">
				<div class="d-flex justify-content-between">
					<div>
						<p class="fs-6 text-bolder m-0">Questo sito utilizza <i>cookie</i> tecnici. Proseguendo nella navigazione accetti l'utilizzo dei cookie.</p>

						<p class="fs-6 m-0">
							Per maggiori informazioni,
							<span
								class="text-bolder text-decoration-underline cursor-pointer"
								@click="isCookiesModalVisible = true"
							>
								leggi l'informativa completa.
							</span>
						</p>
					</div>

					<div>
						<i
							class="fas fa-circle-xmark text-lg text-dark cursor-pointer"
							title="Ho letto e compreso"
							@click="handleCookiesDismiss()"
						></i>
					</div>
				</div>
			</div>
		</div>
	</div>

	<main class="main-content mt-0">
		<div class="page-header bg-dark align-items-start min-vh-25 pt-2 pb-10 m-3 border-radius-lg">
			<span class="mask bg-gradient-dark opacity-6"></span>

			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-5 text-center mx-auto">
						<h1 class="fw-light text-white fs-4 mb-2 mt-4">
							Benvenuto nel
							<br />
							<span class="fw-normal"> Portale di Gestione delle Istanze delle Farmacie </span>
						</h1>

						<!-- <div class="img-container">
                            <img src="../assets/img/just_heal_logo.png" alt="">
                        </div> -->
					</div>
				</div>
			</div>
		</div>

		<div class="container">
			<div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
				<div class="col-xl-6 col-lg-7 col-md-8 mx-auto">
					<div class="card shadow-lg">
						<div class="pb-0 px-5 card-header text-start">
							<h5 class="font-weight-bolder fs-4">Login</h5>

							<p class="mb-0 fs-6">Effettuando l'accesso con SPID, verrai reindirizzato a una pagina esterna per completare la procedura di accesso</p>
						</div>

						<div class="card-body p-5 pt-3">
							<div class="text-center">
								<ArgonButton
									class="mt-4 btn primary fs-5"
									fullWidth
									size="lg"
									@click.prevent="loginWithKeycloak()"
									>Accedi con SPID</ArgonButton
								>
							</div>
						</div>

						<!-- <div class="px-1 pt-0 text-center card-footer px-lg-2">
                            <p class="mx-auto mb-4 fs-5">
                                Non hai ancora un account?
                                <router-link
                                    :to="{ name: 'Signup' }"
                                    class="text-dark font-weight-bolder text-decoration-underline fs-5"
                                >Registrati</router-link>
                            </p>
                        </div> -->
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import axios from 'axios';
import CookiesComponent from '../components/CookiesComponent.vue';
import ArgonButton from '@/components/ArgonButton.vue';
import ArgonAlert from '../components/ArgonAlert.vue';
import keycloak from '../keycloak';

const body = document.getElementsByTagName('body')[0];
const VALID_EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export default {
	name: 'SigninView',
	components: {
		ArgonAlert,
		CookiesComponent,
		ArgonButton,
	},
	data() {
		return {
			isCookiesModalVisible: false,
			email: '',
			password: '',
			isFormInvalid: false,
			isToastVisible: false,
			errorDescription: null,
			isCookieRead: false,
		};
	},
	created() {
		this.$store.state.hideConfigButton = true;
		this.$store.state.showNavbar = false;
		this.$store.state.showSidenav = false;
		this.$store.state.showFooter = false;
		body.classList.remove('bg-gray-100');
	},
	mounted() {
		if (sessionStorage.getItem('cookies') === null || sessionStorage.getItem('cookies') === 'false') {
			sessionStorage.setItem('cookies', false);
		} else {
			this.isCookieRead = true;
		}

		setTimeout(() => {
			if (localStorage.getItem('access-token') !== null) {
				this.login();
			}
		}, 1000);
	},
	beforeUnmount() {
		this.$store.state.hideConfigButton = false;
		this.$store.state.showNavbar = true;
		this.$store.state.showSidenav = true;
		this.$store.state.showFooter = true;
		body.classList.add('bg-gray-100');
	},
	methods: {
		login() {
			axios
				.get(`/login/access`)
				.then((res) => {
					if (res.data.user.type === 1) {
						this.$router.replace({ name: 'Dashboard' });
					} else {
						this.$router.replace({ name: 'DashboardAmministratori' });
					}
				})
				.catch((err) => {
					this.errorDescription = err.message;
					this.isToastVisible = true;
					setTimeout(() => {
						this.logoutWithKeycloak();
					}, 500);
				});
		},

		async loginWithKeycloak() {
			try {
                const authConfig = {
                    scope: 'openid',
                    redirectUri: window.location.origin + '/keycloak-callback',
                }

				const authUrl = await keycloak.createLoginUrl(authConfig);

				keycloak.login(authUrl);
			} catch (error) {
				console.error("Non è stato possibile creare l'URL per la login keycloak");
			}
			// window.location.href = authUrl;
		},

		logoutWithKeycloak() {
			keycloak.logout();
			localStorage.clear();
		},

		handleToastDismiss() {
			this.isToastVisible = false;
			this.errorDescription = null;
		},

		handleCookiesDismiss() {
			this.isCookiesModalVisible = false;
			sessionStorage.setItem('cookies', 'true');
			this.isCookieRead = true;
		},
	},
	computed: {
		isEmailValid() {
			return VALID_EMAIL_REGEX.test(this.email);
		},

		isPasswordValid() {
			return this.password.length >= 4;
		},
	},
};
</script>

<style scoped>
.toastr {
	opacity: 0;
	bottom: -200px !important;
	transition: 0.6s;
	width: max(30%, 270px);
}

.toastr.active {
	opacity: 1;
	bottom: 0 !important;
}

.img-container {
	width: min(100%, 300px);
	margin: 0 auto;
}

.img-container img {
	width: 100%;
	display: block;
}

.cookie-banner {
	position: absolute;
	z-index: 99999;
	bottom: 1%;
	left: 1%;
	right: 1%;
}

.mask {
	z-index: 0;
}
</style>
