<template>
    <Loader :active="isLoading"/>

    <ArgonAlert
        icon="fa-solid fa-circle-exclamation text-white"
        :color="fetchError !== null ? 'danger' : 'success'"
        :isShown="isToastVisible"
        :dismissible="true"
        class="toastr col-2 text-sm position-fixed bottom-0 end-0"
        :class="isToastVisible ? 'active' : ''"
        @modalDismiss="handleToastDismiss()"
    >
        <template #modalText>
            <p v-if="fetchError" class="text-break fs-6 text-white mb-0">{{ fetchError }}</p>
            <p v-else class="text-break fs-6 text-white mb-0">L'operazione è stata completata con successo</p>
        </template>
    </ArgonAlert>

    <transition name="modal">
        <ModalComponent v-if="isDetailsModalVisible"
            :isSuccess="true"
            :isFooterRequired="true"
        >
            <template #header>Attenzione</template>

            <template #body>
                <div class="pt-3">
                    <p class="text-sm text-bold text-dark">
                        Ricordati di compilare tutti i dati inerenti alla/e farmacia/e per permettere una precompilazione dei documenti più completa
                    </p>   
                </div>
            </template>

            <template #footer>
                <ArgonButton
                    class="btn secondary"
                    color="primary"
                    size="sm"
                    @click="handleDetailsModalDismiss"
                >Chiudi</ArgonButton>
            </template>
        </ModalComponent>
    </transition>

    <UploadFileModalComponent v-if="isUploadFileModalVisible && selectedInstance !== null"
        :userType="user.extUserTypeId"
        :selectedInstance="selectedInstance"
        @showLoader="showLoader"
        @hideLoader="hideLoader"
        @handleFileUploaded="handleFileUploaded"
        @handleToast="handleToastMessage"
        @dismiss="handleFileUploadModalDismiss"
    />

    <DeleteModalComponent v-if="isDeleteModalVisible && instanceToDelete !== null"
        :instanceDetails="instanceToDelete"
        @showLoader="showLoader"
        @hideLoader="hideLoader"
        @handleInstanceDeleted="handleInstanceDeleted"
        @handleToast="handleToastMessage"
        @dismiss="handleDeleteModalDismiss"
    />

    <UploadImageModalComponent v-if="isImageUploadModalVisible"
        @showLoader="showLoader"
        @hideLoader="hideLoader"
        @handleImageUploaded="handleImageUploaded"
        @handleToast="handleToastMessage"
        @dismiss="handleImageUploadModalDismiss"
    />

    <div class="container-fluid pb-3 overflow-hidden">
        <div class="row mx-0 g-4">
            <div class="col-md-7">
                <DashboardCard v-if="user">
                    <template #cardHeader>
                        <h5 class="text-uppercase fw-normal text-dark fs-5">Anagrafica</h5>
                    </template>
                
                    <template #cardBody>
                        <div class="row nowrap">
                            <div image class="col-md-4 d-flex align-items-center">
                                <div class="user-info-image rounded-3 position-relative">
                                    <div class="img-container overflow-hidden rounded-3">
                                        <ImageWithDefault
                                            :defaultImagePath="require('../assets/img/default-user.jpg')"
                                            :imgAlt="'Immagine Profilo'"
                                            :imageUrl="user.imageUrl ?? ''"
                                        />
                                    </div>

                                    <ArgonButton
                                        class="img-modifier-btn bg-primary position-absolute"
                                        color="dark"
                                        size="sm"
                                        @click.prevent="isImageUploadModalVisible = true;"
                                    >
                                        <i class="fa-solid fa-pencil text-white text-sm"></i>
                                    </ArgonButton>
                                </div>
                            </div>
            
                            <div class="card shadow-none col-md-8 flex-column justify-content-around lh-lg pe-0 user-info">
                                <div class="border-bottom">
                                    <span class="fs-6 fw-bold"> Nome&colon; </span>
                                    <span class="fs-6"> {{ user.nome }} </span>
                                </div>
            
                                <div class="border-bottom">
                                    <span class="fs-6 fw-bold"> Cognome&colon; </span>
                                    <span class="fs-6"> {{user.cognome}} </span>
                                </div>
            
                                <div class="border-bottom">
                                    <span class="fs-6 fw-bold"> Cod. Fiscale&colon; </span>
                                    <span class="fs-6"> {{ user.codiceFiscale}} </span>
                                </div>
            
                                <div class="border-bottom">
                                    <span class="fs-6 fw-bold"> Email&colon; </span>
                                    <span class="fs-6"> {{user.email}} </span>
                                </div>

                                <div v-if="mainUser" class="border-bottom">
                                    <span class="fs-6 fw-bold"> Delegato di&colon; </span>
                                    <span class="fs-6"> {{mainUser.nome}} {{ mainUser.cognome }} </span>
                                </div>

                                <div v-if="!mainUser && user.userGuest" class="border-bottom">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span class="fs-6">
                                            <span class="fs-6 fw-bold">Scarica documento delegato&colon;</span>
                                            {{user.userGuest.nome}} {{ user.userGuest.cognome }}
                                        </span>

                                        <i
                                            class="fas fa-cloud-arrow-down text-dark text-sm pe-lg-2"
                                            title="Scarica documento delegato"
                                            role="button"
                                            @click.prevent="() => downloadDelegationDocument()"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </DashboardCard>
            </div>

            <div class="col-md-5">
                <DashboardCard v-if="user">
                    <template #cardHeader>
                        <h5 class="text-uppercase fw-normal text-dark fs-5">Notifiche</h5>
                    </template>
                
                    <template #cardBody>
                        <div v-if="notificationsList.length > 0">
                            <div v-for="notification in notificationsList"
                                :key="notification.notificheId"
                                class="d-flex justify-content-start"
                            >
                                <div
                                    class="notification-bubble bubble w-90 mb-2 pb-1"
                                    :class="notification.status === 0 ? 'unread' : 'read'"
                                >
                                    <p class="mb-1 text-sm">{{ notification.description }}</p>
    
                                    <div class="text-xs text-end text-dark m-0 p-0 mt-2">{{notification.date}}</div>
                                </div>

                                <div v-if="notification.status === 0" class="w-10 text-end">
                                    <i
                                        role="button"
                                        title="Conferma lettura"
                                        class="fa-solid fa-circle-check text-lg"
                                        @click.prevent="handleNotificationRead(notification.notificheId)"
                                    ></i>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <p class="fs-6 text-dark font-weight-bold">Nessuna notifica</p>
                        </div>

                        <div v-if="isNotificationsListRead" class="text-center">
                            <p
                                class="text-xxs text-dark font-weight-bold text-decoration-underline"
                                role="button"
                                @click.prevent="handleAllNotificationsRead()"
                            >Segna tutte come lette</p>
                        </div>
                    </template>
                </DashboardCard>
            </div>
        </div>

         <div class="row mx-0">
            <div class="col-md-12">
                <DashboardCard v-if="user">
                    <template #cardHeader>
                       <h5 class="text-uppercase fw-normal text-dark fs-5">Istanze</h5>
                    </template>
            
                    <template #cardBody>
                        <div class="card-body px-0 pt-0 pb-2">
                            <div class="table-responsive p-0">
                                <table class="table align-items-center mb-0">
                                    <thead>
                                        <tr>
                                            <th class="text-center text-uppercase text-secondary fs-6 font-weight-bolder opacity-7">ID Istanza</th>
                                            <th class="text-center text-uppercase text-secondary fs-6 font-weight-bolder opacity-7">Nome Istanza</th>
                                            <th class="text-center text-uppercase text-secondary fs-6 font-weight-bolder opacity-7">Ultima Modifica</th>
                                            <th class="text-center text-uppercase text-secondary fs-6 font-weight-bolder opacity-7">Stato</th>
                                            <th class="text-center text-uppercase text-secondary fs-6 font-weight-bolder opacity-7">Azioni</th>
                                        </tr>
                                    </thead>
                        
                                    <tbody v-if="instances && instances.length > 0">
                                        <tr v-for="instance in instances" :key="instance.istanzaId">
                                            <td class="align-middle text-center">
                                                <p class="text-sm text-secondary font-weight-bold mb-0">
                                                    {{ instance.istanzaId }}
                                                </p>
                                            </td>

                                            <td class="align-middle text-center position-relative">
                                                <p class="text-sm text-secondary font-weight-bold mb-0">
                                                    {{ instance.tipoIstanza.descrizione }}
                                                </p>

                                                <span v-if="!instance.delegato && flagUserGuest === 1" class="position-absolute top-0 end-0">
                                                    <i class="fa-solid fa-circle-info clr-secondary text-lg"
                                                        :title="`Istanza avviata da: ${instance.istante.nome} ${instance.istante.cognome}`"
                                                    ></i>
                                                </span>

                                                <span v-if="instance.delegato && flagUserGuest === 0" class="position-absolute top-0 end-0">
                                                    <i class="fa-solid fa-circle-info clr-secondary text-lg"
                                                        :title="`Istanza avviata da: ${instance.istante.userGuest.nome} ${instance.istante.userGuest.cognome}`"
                                                    ></i>
                                                </span>
                                            </td>

                                            <td class="align-middle text-center">
                                                <p class="text-sm text-secondary font-weight-bold mb-0"> 
                                                    {{ this.formatStringToDate(instance.dataUltimaModifica) }}
                                                </p>
                                            </td>
                                                
                                            <td class="align-middle text-center">
                                                <div v-if="instance.statoCamunda">
                                                    <p
                                                        class="badge bg-primary text-xs font-weight-bold mb-0"
                                                        :class="{
                                                            'bg-danger': instance.statoCamunda === 'note',
                                                            'bg-warning': instance.statoCamunda === 'bozza'
                                                        }"
                                                    >{{ formatCamundaState(instance.statoCamunda) }}</p>
                                                </div>

                                                <div v-else>
                                                    <p class="text-xs text-secondary font-weight-bold mb-1">COMPLETAMENTO</p>
                                                    <ArgonProgress
                                                        class="w-80 mx-auto"
                                                        :percentage="getInstanceProgressBar(instance.tipoIstanza.tipoIstanzaId, instance.stato)"
                                                    />
                                                </div>
                                            </td>

                                            <td class="align-middle text-center text-xxs">
                                                <div v-if="getAllowedAction(instance.statoCamunda) === 'view'" class="d-flex w-100 justify-content-center align-items-center gap-3">
                                                    <router-link
                                                        :to="user.extUserTypeId === 1 ? {
                                                            name: 'ReviewRequest',
                                                            params: {
                                                                instanceId: instance.istanzaId,
                                                                instanceTypeId: instance.extTipoIstanzaId,
                                                                action: 'view'
                                                            }
                                                        } : {
                                                            name: 'ReviewEntrustedRequest',
                                                            params: {
                                                                instanceId: instance.istanzaId,
                                                                instanceTypeId: instance.extTipoIstanzaId,
                                                                action: 'view',
                                                            }
                                                        }"
                                                    >
                                                        <i class="fa-solid fa-eye text-lg" title="Rivedi Istanza"></i>
                                                    </router-link>
                                                    
                                                    <i
                                                        v-if="user.extUserTypeId === 1 && instance.statoCamunda === 'inviata'"
                                                        role="button"
                                                        class="fa-solid fa-trash-can text-danger text-lg"
                                                        title="Elimina Istanza"
                                                        @click.prevent="handleOpenDeleteModal(instance)"
                                                    ></i>
                                                </div>

                                                <div v-else-if="getAllowedAction(instance.statoCamunda) === 'edit'" class="d-flex w-100 justify-content-around align-items-center gap-3">
                                                    <router-link
                                                        :to="{
                                                            name: 'ReviewRequest',
                                                            params: {
                                                                instanceId: instance.istanzaId,
                                                                instanceTypeId: instance.extTipoIstanzaId,
                                                                action: 'edit'
                                                            }
                                                        }"
                                                    >
                                                        <i class="fa-solid fa-pencil text-lg" title="Modifica Istanza"></i>
                                                    </router-link>

                                                    <i
                                                        v-if="instance.statoCamunda !== 'note' && instance.statoCamunda !== 'bozza'"
                                                        role="button"
                                                        class="fa-solid fa-trash-can text-danger text-lg"
                                                        title="Elimina Istanza"
                                                        @click.prevent="handleOpenDeleteModal(instance)"
                                                    ></i>
                                                </div>
                                                
                                                <div v-else-if="getAllowedAction(instance.statoCamunda) === 'download'">
                                                    <i
                                                        class="fa-solid text-sm fa-cloud-arrow-down text-lg"
                                                        role="button"
                                                        title="Scarica Decreto"
                                                        @click.prevent="handleDownload(instance)"
                                                    ></i>
                                                </div>

                                                <div v-else-if="getAllowedAction(instance.statoCamunda) === 'no-action-available'">
                                                    <i
                                                        class="fa-solid fa-ban text-lg"
                                                        role="button"
                                                        title="Atteso intervento ASL"
                                                    ></i>
                                                </div>

                                                <div v-else-if="getAllowedAction(instance.statoCamunda) === 'upload'">
                                                    <i
                                                        class="fa-solid text-lg fa-cloud-arrow-up"
                                                        role="button"
                                                        title="Upload Decreto"
                                                        @click.prevent="handleUploadAction(instance)"
                                                    ></i>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>

                                    <tbody v-else>
                                        <tr>
                                            <td class="align-middle text-center">
                                                <p class="text-sm text-secondary font-weight-bold mb-0">-</p>
                                            </td>

                                            <td class="align-middle text-center">
                                                <p class="text-sm text-secondary font-weight-bold mb-0">-</p>
                                            </td>

                                            <td class="align-middle text-center">
                                                <p class="text-sm text-secondary font-weight-bold mb-0">-</p>
                                            </td>
                                                
                                            <td class="align-middle text-center">
                                                <p class="text-sm text-secondary font-weight-bold mb-0">-</p>
                                            </td>

                                            <td class="align-middle text-center text-xxs">
                                                <p class="text-sm text-secondary font-weight-bold mb-0">-</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                        
                                <div v-if="!instances || instances.length === 0">
                                    <div class="col-12">
                                        <p class="text-center">Nessuna istanza in corso trovata</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </DashboardCard>
            </div>
         </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { INSTANCES_STEPS_MAP, DASHBOARD_ALLOWED_ACTIONS_MAP, NO_ACTIONS_INSTANCES_BADGE_MAP, SELF_CERTIFICATION_ID } from '../global/constants';
import DashboardCard from '../components/DashboardCard.vue';
import Loader from '../components/Loader.vue';
import ArgonProgress from '../components/ArgonProgress.vue';
import ArgonAlert from '../components/ArgonAlert.vue';
import ArgonButton from '../components/ArgonButton.vue';
import ModalComponent from '../components/modal/ModalComponent.vue';
import DeleteModalComponent from '../components/modal/components/DeleteModalComponent.vue';
import UploadImageModalComponent from '../components/modal/components/UploadImageModalComponent.vue';
import UploadFileModalComponent from '../components/modal/components/UploadFileModalComponent.vue';
import ImageWithDefault from '../components/ImageWithDefault.vue';

export default {
    name: "dashboard-default",
    components: {
        DashboardCard,
        ImageWithDefault,
        Loader,
        ArgonProgress,
        ArgonAlert,
        ArgonButton,
        ModalComponent,
        DeleteModalComponent,
        UploadImageModalComponent,
        UploadFileModalComponent
    },
    data() {
        return {
            isLoading: false,
            isDetailsModalVisible: false,
            isToastVisible: false,
            isUploadFileModalVisible: false,
            isDeleteModalVisible: false,
            isImageUploadModalVisible: false,
            instanceToDelete: null,
            fetchError: null,
            user: null,
            mainUser: null,
            instances: [],
            selectedInstance: null,
            notificationsList: [],
            flagUserGuest: 0
        };
    },
    mounted() {
        this.refreshDashboard();

        if(!sessionStorage.getItem('isNewSession')) {
            sessionStorage.setItem('isNewSession', 'true');
            this.isDetailsModalVisible = true;
        }

        this.flagUserGuest = JSON.parse(localStorage.getItem('user')).flagUserGuest;
    },
    methods: {
        showLoader() {
            this.isLoading = true;
        },

        hideLoader() {
            this.isLoading = false;
        },

        refreshDashboard() {
            this.showLoader();

            axios.get(`/dashboard/refresh`)
                .then(res => {
                    if(this.flagUserGuest === 1) {
                        this.user = res.data.userGuest;
                        this.mainUser = res.data.anagrafica
                    } else {
                        this.user = res.data.anagrafica;
                    }

                    this.$store.dispatch("setUser", this.user);
                    this.instances = res.data.listaIstanze;
                    this.notificationsList = res.data.listaNotifiche;
                    this.refreshNotificationsBadge();
                    this.refreshInstancesBadge();
                })
                .catch(err => {
                    this.fetchError = err.message;
                    this.isToastVisible = true;
                })
                .finally(() => this.hideLoader());
        },

        formatCamundaState(state) {
            if(state === null) return null;
            return state.toUpperCase().replace(/_/g, ' ');
        },

        refreshNotificationsBadge() {
            const notifications = this.notificationsList.reduce((acc, notification) => {
                return acc + (notification.status === 0 ? 1 : 0);
            }, 0);
            
            this.$store.dispatch('refreshNotificationsBadge', notifications)
        },

        refreshInstancesBadge() {
            axios.get('/istanze/userInstances')
                .then(res => {
                    const instancesNum = res.data.istanzeList
                        .filter(instance => !NO_ACTIONS_INSTANCES_BADGE_MAP[1].includes(instance.statoCamunda)).length;
                    this.$store.dispatch('setNewIstances', instancesNum);
                })
        },

        formatStringToDate(dateMillis) {
            let date = new Date(dateMillis);
            let year = date.getFullYear();
            let month = ((date.getMonth() + 1).toString()).padStart(2, '0');
            let day = (date.getDate().toString().padStart(2, '0'));
            let parsedDate = `${day}-${month}-${year}`;

            return parsedDate;
        },

        handleDownload(instance) {
            this.showLoader();

            let url = null;
            let filename = `DECRETO_${Date.now()}`;

            if(instance.statoCamunda === 'scarica_autocertificazione') {
                url = `/document/downloadFromHtml/${SELF_CERTIFICATION_ID}/${instance.istanzaId}/${instance.extFarmaciaId}`;
                filename = 'autocertificazione_' + instance.tipoIstanza.descrizione.toLowerCase().replace(/ /g, "_");
                filename = filename.concat(`_${Date.now()}`);
            } else {
                url = `/document/get/decreto/${instance.decreto.bucketName}`;
            }

            axios.get(url, { responseType: 'blob' })
                .then(res => {
                    const href = URL.createObjectURL(res.data);
                    const link = document.createElement('a');

                    link.href = href;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);

                    this.refreshDashboard();
                })
                .catch(err => {
                    this.hideLoader();
                    this.fetchError = err.message;
                    this.isToastVisible = true;
                })
        },

        handleUploadAction(instance) {
            this.isUploadFileModalVisible = true;
            this.selectedInstance = instance;
        },

        handleFileUploaded() {
            this.handleFileUploadModalDismiss();
            this.isToastVisible = true;
            this.refreshDashboard();
        },

        handleOpenDeleteModal(instance) {
            this.instanceToDelete = instance;
            this.isDeleteModalVisible = true;
        },

        handleInstanceDeleted() {
            this.handleDeleteModalDismiss();
            this.isToastVisible = true;
            this.refreshDashboard();
        },

        handleToastMessage(message) {
            this.fetchError = message;
            this.isToastVisible = true;
        },

        handleNotificationRead(notificationId) {
            this.showLoader();

            const data = { notificaId: notificationId };

            axios.post(`/dashboard/confermaLettura`, data)
                .then(() => {
                    this.isToastVisible = true;
                    this.refreshDashboard();
                })
                .catch(err => {
                    this.hideLoader();
                    this.fetchError = err.message;
                    this.isToastVisible = true;
                })
        },

        handleAllNotificationsRead() {
            this.showLoader();

            const data = { notificheList: this.notificationsList };

            axios.post(`/dashboard/confermaTutte`, data)
                .then(() => {
                    this.isToastVisible = true;
                    this.refreshDashboard();
                })
                .catch(err => {
                    this.hideLoader();
                    this.fetchError = err.message;
                    this.isToastVisible = true;
                })
        },

        handleImageUploaded() {
            this.isImageUploadModalVisible = false;
            this.isToastVisible = true;
            this.refreshDashboard();
        },

        downloadDelegationDocument() {
            this.showLoader();

            axios.get(`/document/downloadAutodichiarazione`, { responseType: 'blob' })
                .then(res => {
                    const href = URL.createObjectURL(res.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `DELEGA_${Date.now()}`);
                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                })
                .catch(err => {
                    this.fetchError = err.message;
                    this.isToastVisible = true;
                })
                .finally(() => this.hideLoader())
        },

        handleFileUploadModalDismiss() {
            this.isUploadFileModalVisible = false;
            this.selectedInstance = null;
        },

        handleDeleteModalDismiss() {
            this.isDeleteModalVisible = false;
            this.instanceToDelete = null;
        },

        handleImageUploadModalDismiss() {
            this.isImageUploadModalVisible = false;
        },

        handleDetailsModalDismiss() {
            this.isDetailsModalVisible = false;
            sessionStorage.setItem('isNewSession', 'false');
        },

        handleToastDismiss() {
            this.isToastVisible = false;
            setTimeout(() => this.fetchError = null, 100);
        }
    },
    computed: {
        ...mapState(['notifications', 'newInstances']),

        getAllowedAction() {
            return (camundaState) => DASHBOARD_ALLOWED_ACTIONS_MAP[camundaState] ?? 'view';
        },

        isNotificationsListRead() {
            return this.notificationsList.some(notification => notification.status === 0);
        },

        getInstanceProgressBar() {
            return (instanceType, step) => {
                const totalSteps = INSTANCES_STEPS_MAP[instanceType] ?? 0;
                const percentage = Math.round((step * 100) / totalSteps);
                return percentage.toString();
            }
        },
    }
};

</script>

<style scoped>
.user-info div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.img-container {
    width: min(100%, 300px);
}

img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
}

h5,
h6 {
    margin-bottom: 0;
}

h6 {
    display: inline-block;
    font-size: 1.1rem;
    padding-right: 1rem;
}

span {
    font-size: 1rem;
}

.notification-bubble {
    width: 80%;
    border-radius: .5rem;
    padding: 1em;
    font-size: .7rem;
    color: #333;
    position: relative;
}

.card.shadow-none div + div {
    padding-top: 1rem;
}

.user-info-image {
    box-shadow: 0px 0px 8px #ebebeb;
    width: min(100%, 300px);
    aspect-ratio: 1;
}

@media screen and (width < 768px) {
    .col-md-4[image] {
        display: flex;
        justify-content: center;
    }

    .user-info-image {
        margin-bottom: 2rem;
    }
}

.user-info-image img {
    width: 100%;
    display: block;
}

.img-modifier-btn {
    width: 2rem;
    height: 2rem;
    padding: 1rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    bottom: .75em;
    right: .75em;
}

.container-fluid > .row:nth-child(1) {
    margin-bottom: 2rem;
}

tr {
    height: 45px;
}
</style>
